import * as React from 'react';
import { Grid, Typography, Divider, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, FormHelperText, FormControl, Input } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useUserContext } from '../../RegisterForm/UserContext';
import KenButton from '../../../GlobalComponents/KenButton';
import KenLoader from '../../../GlobalComponents/KenLoader';
import { onboarding } from '../../../Utils/apiService';

function AddressDetails() {
    const userData = useUserContext();
    const [open, setOpen] = React.useState(false);
    const [pincode, setPincode] = React.useState(userData?.pincode || '');
    const [address, setAddress] = React.useState({ city: '', state: '', country: '' });
    const [loader, setLoader] = React.useState<boolean>(false);
    const [pincodeError, setPincodeError] = React.useState<string | null>(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const UserData = localStorage.getItem("userData");
    const userDatas = UserData ? JSON.parse(UserData) : null;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePincodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const pincode = e.target.value;
        setPincode(pincode);

        if (pincode.length === 6) {
            setLoader(true);
            try {
                const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
                const data = await response.json();
                if (data[0].Status === "Success") {
                    const postOffices = data[0].PostOffice;
                    const newAddress = {
                        country: postOffices[0].Country,
                        state: postOffices[0].State,
                        city: postOffices[0].District,
                    };
                    setAddress(newAddress);
                    setPincodeError(null);
                } else {
                    setPincodeError("Please enter a valid pincode closest to your location.");
                    setAddress({ country: '', state: '', city: '' });
                }
            } catch (error) {
                console.error("Error fetching pincode data:", error);
                setPincodeError("Error fetching pincode data. Please try again.");
                setAddress({ country: '', state: '', city: '' });
            } finally {
                setLoader(false);
            }
        } else {
            setPincodeError(null);
            setAddress({ country: '', state: '', city: '' });
        }
    };
    const onSubmit = () => {
        const userUpdate: any = {
            method: 'post',
            users: {
                id: userDatas?.user_id,
                country: "India",
                customfields: [
                    {
                        type: 'pincode',
                        value: pincode
                    },
                    {
                        type: 'state',
                        value: address.state,
                    },
                    {
                        type: 'city',
                        value: address.city
                    },
                ],
            },
        };
        onboarding(userUpdate).then((res: any) => { 
            window.location.reload();
        })
        handleClose();
    };
    return (
        <Grid style={{ backgroundColor: "#f6f8fb", padding: 20 ,borderRadius:12}}>
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontSize={14} fontWeight={500}>Address</Typography>
                <KenButton variant="outlined" onClick={handleClickOpen} style={{ float: "right", padding: 0 }}>
                    Edit
                </KenButton>
            </Grid>
            <Divider style={{ paddingTop: 10 }} />
            <Grid container paddingTop={1}>
                <Grid item lg={3}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Pincode</Typography>
                    <Typography>{userData?.pincode}</Typography>
                </Grid>
                <Grid item lg={3}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>City/District</Typography>
                    <Typography>{userData?.city}</Typography>
                </Grid>
                <Grid item lg={3}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>State</Typography>
                    <Typography>{userData?.state}</Typography>
                </Grid>
                <Grid item lg={3}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Country</Typography>
                    <Typography>{"India"}</Typography>
                </Grid>
            </Grid>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    style: {
                        width: '600px',
                        maxWidth: 'none',
                        height: "50vh"
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Edit Address Details"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the pincode to fetch the address details.
                    </DialogContentText>
                    {loader && <KenLoader />}
                    <FormControl fullWidth margin="normal">
                        <Input
                            value={pincode}
                            onChange={handlePincodeChange}
                            placeholder="Enter Pincode"
                            inputProps={{ maxLength: 6 }}
                            aria-describedby="pincode-error-text"
                        />
                        {pincodeError && <FormHelperText id="pincode-error-text" error>{pincodeError}</FormHelperText>}
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <label style={{ fontWeight: 500, marginBottom: 10 }}>City/District</label>
                        <Input
                            value={address.city}
                            disabled
                            placeholder="City/District"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <label style={{ fontWeight: 500, marginBottom: 10 }}>State</label>
                        <Input
                            value={address.state}
                            disabled
                            placeholder="State"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <label style={{ fontWeight: 500, marginBottom: 10 }}>Country</label>
                        <Input
                            value={address.country}
                            disabled
                            placeholder="Country"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={onSubmit}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default AddressDetails;