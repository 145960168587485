const routes = {
  login: '/login',
  otpverification: '/otpverification',
  emailverification: '/emailverification',
  signup: '/signup',
  onboarding: '/onBoarding',
  forgotPassword: '/forgotPassword',
  changePassword: '/changePassword',
  home: '/home',
  courses: '/courses',
  Assessments: '/assessments',
  stepper: '/stepper',
  Assesmentstest: '/Assesmentstest',
  TestAssesments: '/TestAssesments',
  register: '/register',
  learninginterest: '/learninginterest',
  profile:"/profile"
};

export default routes;
