import * as React from 'react';
import { Grid, Typography, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, Box, Checkbox, FormControlLabel, Snackbar, SnackbarContent } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useUserContext } from '../../RegisterForm/UserContext';
import KenButton from '../../../GlobalComponents/KenButton';
import CloseIcon from '@mui/icons-material/Close';
import { getLearningInterests, onboarding } from '../../../Utils/apiService';
import image1 from '../../../Assets/Rectangle 3463894.png';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
interface Record {
    id: number;
    name: string;
    image: string;
}
function Learninginterests() {
    const userData = useUserContext();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const UserData = localStorage.getItem("userData");
    const userDatas = UserData ? JSON.parse(UserData) : null;
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [interestNames, setInterestNames] = React.useState<string[]>([]);
    const { register, handleSubmit, watch } = useForm({ mode: 'onChange' });
    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
    const [records, setRecords] = React.useState<Record[]>([]);
    React.useEffect(() => {
        getLearningInterests()
            .then((res: any) => {
                const interestIds = userData?.area_interest || [];
                const names = interestIds.map((id: string) => {
                    const interest = res?.data.find((item: any) => item.id === id);
                    return interest ? interest.name : "";
                });
                setInterestNames(names);

                const apiRecords: Record[] = res.data.map((item: any) => ({
                    id: item.id,
                    name: item.name,
                    image: image1,
                }));
                setRecords(apiRecords);
            })
            .catch((error: any) => {
                console.error('Error fetching data:', error);
            });
    }, [userData?.area_interest]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCheckboxChange = (id: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            if (selectedItems.length < 3) {
                setSelectedItems((prevSelected) => [...prevSelected, id]);
            }
        } else {
            setSelectedItems((prevSelected) => prevSelected.filter((item) => item !== id));
        }
    };
    const onSubmit = (data: any) => {
        localStorage.removeItem("mobile");
        const userUpdate: any = {
            method: 'post',
            users: {
                id: userDatas?.user_id,
                customfields: [
                ],
                interests: selectedItems.join(','),
            },
        };
        onboarding(userUpdate).then((res: any) => {
            if (res?.status === 200) {
                window.location.reload();
            } else {
                alert("error");
            }
        });
        handleClose();
    };
    return (
        <Grid style={{ backgroundColor: "#f6f8fb", padding: 20, borderRadius: 12 }}>
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontSize={14} fontWeight={500}>Learning Interests</Typography>
                <KenButton variant="outlined" onClick={handleClickOpen} style={{ float: "right", padding: 0 }}>
                    Edit
                </KenButton>
            </Grid>
            <Divider style={{ paddingTop: 10 }} />
            <Grid container paddingTop={1}>
                <Grid item lg={4}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Option 1</Typography>
                    <Typography>{interestNames[0] ? interestNames[0] : "-"}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Option 2</Typography>
                    <Typography>{interestNames[1] ? interestNames[1] : "-"}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Option 3</Typography>
                    <Typography>{interestNames[2] ? interestNames[2] : "-"}</Typography>
                </Grid>
            </Grid>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    style: {
                        width: '100%',
                        maxWidth: 'none',
                        height: "100%"
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Edit Learning Interests"}
                    <Typography style={{ float: "right" }}>
                        <CloseIcon onClick={handleClose} />
                    </Typography>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <Box
                        component={'form'}
                    >
                        <div style={{ margin: '30px', height: 'calc(100% - 120px)', overflowY: 'auto' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '10px'
                                }}
                            >
                                <Typography color="#262728" style={{ textAlign: 'left' }}>
                                    Learning Interests
                                </Typography>
                                <Grid container spacing={1} style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {records.map((record: any) => (
                                        <Grid item key={record.id} xl={2} lg={3} md={3} style={{ marginTop: '20px' }}>
                                            <Box style={{ backgroundColor: '#F6F8FB', borderRadius: '12px', border: selectedItems.includes(record.name) ? '1px solid #1976D2' : 'none' }}>
                                                <div style={{ margin: '10px' }}>
                                                    <img src={record.image} alt={record.name} style={{ width: '100%', marginTop: '10px' }} />
                                                    <div style={{ display: 'flex' }}>
                                                        <FormControlLabel
                                                            style={{ marginBottom: '30px' }}
                                                            control={<Checkbox checked={selectedItems.includes(record.id)} onChange={handleCheckboxChange(record.id)} color="primary" />}
                                                            label={record.name}
                                                        />
                                                    </div>
                                                </div>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button autoFocus onClick={onSubmit}>
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default Learninginterests;