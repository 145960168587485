import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserDetails } from '../../Utils/apiService';

const UserContext = createContext<any>(null);

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  // const [userData, setUserData] = useState<any>(undefined);
  const [userData, setUserDetails] = useState<any>(undefined);
    const [retryCount, setRetryCount] = useState<boolean>(false);
    // const maxRetries = 3;
    const [loading, setLoading] = useState(true);

    const fetchUserDetails = async () => {
        try {
            const res: any = await getUserDetails();
            if (res?.data) {
                setUserDetails(res.data);
                setRetryCount(false);
                setLoading(false);
            } else {
                setRetryCount(true);
            }
        } catch (error) {
            console.error("Failed to fetch user details:", error);
            // handleRetry();
        }
    };

    // const handleRetry = () => {
    //     if (retryCount < maxRetries) {
    //         setRetryCount((prevCount: number) => prevCount + 1);
    //     } else {
    //         setUserDetails(null);
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        fetchUserDetails();
    }, [retryCount]);

    // useEffect(() => {
    //     if (userDetails === undefined && retryCount > 0 && retryCount <= maxRetries) {
    //         fetchUserDetails();
    //     }
    // }, [userDetails, retryCount]);


  return (
    <UserContext.Provider value={userData}>
      {children}
    </UserContext.Provider>
  );
};
