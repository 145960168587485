import { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface CircularProgressChartProps {
  attempted: number;
  unattempted: number;
  revisit: number;
  answeredAndFlagged:any
}

const CircularProgressChartjs: React.FC<CircularProgressChartProps> = ({ attempted, unattempted, revisit,answeredAndFlagged }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | any>(null);
  useEffect(() => {
    const createChart = () => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          if (chartInstance.current) {
            chartInstance.current.destroy();
          }
          const customTextPlugin = {
            id: 'customText',
            beforeDraw: function (chart: any) {
              const width = chart.width;
              const height = chart.height;
              const ctx = chart.ctx;
              ctx.restore();
              const text1 = attempted+unattempted+revisit+answeredAndFlagged;
              const text2 = 'Total questions';
              const fontSize1 = 28;
              ctx.font = `600 ${fontSize1}px sans-serif`;
              ctx.textBaseline = 'middle';
              ctx.textAlign = 'center';
              const textX = width / 2;
              const textY1 = height / 2 - fontSize1 / 2;
              ctx.fillText(text1, textX, textY1);
              const fontSize2 = 18;
              const padding = 10;
              ctx.font = `${fontSize2}px sans-serif`;
              const textY2 = textY1 + padding + fontSize2;
              ctx.fillText(text2, textX, textY2);
              ctx.save();
            },
          };

          chartInstance.current = new Chart(ctx, {
            type: 'doughnut',
            data: {
              // labels: ["Attempted", "Unattempted", "Marked for revisit"],
              datasets: [
                {
                  data: [attempted, unattempted, revisit,answeredAndFlagged],
                  backgroundColor: ['#7AB37C', '#585A64', '#FCD572',"#7495FF"],
                  // hoverBackgroundColor: ['#7AB37C', '#E27474', '#FCD572'],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              cutout: '70%',
              responsive: true,
              maintainAspectRatio: false,
            },
            plugins: [customTextPlugin],
          });
        }
      }
    };

    createChart();

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [attempted, unattempted, revisit,answeredAndFlagged]);

  return (
    <canvas
      ref={(canvas) => {
        chartRef.current = canvas;
      }}
    />
  );
};

export default CircularProgressChartjs;
