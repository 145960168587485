import React from "react";
import { Box, Button, Typography } from "@mui/material";

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <Box role="alert" alignItems="center" justifyContent="center" display="flex" flexDirection="column">
      <Typography style={{ marginTop: 10 }}>Something went wrong</Typography>
      <Button variant="text" className="buttonText-large" onClick={resetErrorBoundary}>
        Click here to Refresh
      </Button>
    </Box>
  );
};

export default ErrorFallback;
