import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import KenColors from './kenColors';
import { ReactChildren } from '../../interfaces/Common';

const theme = createTheme({
  palette: {
    KenColors: { ...KenColors },
    primary: {
      light: '#757ce8',
      main: '#C33531',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#000',
      dark: '#ba000d',
      contrastText: '#000',
    },
    error: {
      main: '#E75547',
    },
    success: {
      main: '#1BBE75',
    },
    card: {
      backgroundColor: '#F7F8FB',
      hover: '#EDF2FF',
    },
    labels: {
      main: '#061938',
      light: '#7A869A',
      medium: '#505F79',
    },
  },
  typography: {
    fontFamily: ['GeneralSans'].join(','),
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'extralargebold' },
          style: {
            fontSize: '24px',
            fontWeight: '600',
          },
        },
        {
          props: { variant: 'extralargemedium' },
          style: {
            fontSize: '24px',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'extralargethin' },
          style: {
            fontSize: '24px',
            fontWeight: '400',
          },
        },
        {
          props: { variant: 'largebold' },
          style: {
            fontSize: '20px',
            fontWeight: '600',
          },
        },
        {
          props: { variant: 'largemedium' },
          style: {
            fontSize: '20px',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'largethin' },
          style: {
            fontSize: '20px',
            fontWeight: '400',
          },
        },
        {
          props: { variant: 'mediumbold' },
          style: {
            fontSize: '16px',
            fontWeight: '600',
          },
        },
        {
          props: { variant: 'medium' },
          style: {
            fontSize: '16px',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontSize: '16px',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'mediumthin' },
          style: {
            fontSize: '16px',
            fontWeight: '400',
          },
        },
        {
          props: { variant: 'smallbold' },
          style: {
            fontSize: '14px',
            fontWeight: '600',
          },
        },
        {
          props: { variant: 'smallmedium' },
          style: {
            fontSize: '14px',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'smallthin' },
          style: {
            fontSize: '14px',
            fontWeight: '400',
          },
        },
        {
          props: { variant: 'extrasmallbold' },
          style: {
            fontSize: '12px',
            fontWeight: '600',
          },
        },
        {
          props: { variant: 'extraverylargebold' },
          style: {
            fontSize: '28px',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'extrasmallmedium' },
          style: {
            fontSize: '12px',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'extrasmallthin' },
          style: {
            fontSize: '12px',
            fontWeight: '400',
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'scrollContained' },
          style: {
            borderRadius: '16px',
            boxShadow: 'none',
            overflowX: 'hidden',
            overflowY: 'auto',
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          padding: '12px',
          borderRadius: '8px',
          boxShadow: 'none',
        },
        disabled: {
          opacity: 0.5,
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            background: KenColors.ClientPrimary,
            color: KenColors.kenWhite,
            ':disabled': {
              color: KenColors.kenWhite,
            },
            ':hover': {
              background: KenColors.ClientPrimary,
            },
          },
        },
        {
          props: { variant: 'containedII' },
          style: {
            background: KenColors.ClientBlue,
            color: KenColors.ClientBlue6,
            ':disabled': {
              color: KenColors.ClientBlue6,
            },
            ':hover': {
              background: KenColors.ClientBlue,
            },
          },
        },
        {
          props: { variant: 'secondarycontained' },
          style: {
            background: KenColors.ClientPrimaryGrey,
            color: KenColors.ClientBlack2,
            ':disabled': {
              color: KenColors.ClientBlack2,
            },
            ':hover': {
              background: KenColors.ClientPrimaryGrey,
            },
          },
        },
        {
          props: { variant: 'secondarycontainedII' },
          style: {
            background: KenColors.kenWhite,
            color: KenColors.ClientBlack2,
            borderRadius: '12px',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            ':disabled': {
              color: KenColors.ClientBlack2,
            },
            ':hover': {
              background: KenColors.kenWhite,
            },
          },
        },
        {
          props: { variant: 'special' },
          style: {
            background: KenColors.ClientBlue4,
            color: KenColors.ClientBlue6,
            padding: '6px 8px',
            borderRadius: '12px',
            ':disabled': {
              color: KenColors.ClientBlue6,
            },
            ':hover': {
              background: KenColors.ClientBlue4,
            },
          },
        },
        {
          props: { variant: 'errorcontained' },
          style: {
            background: KenColors.ClientRed2,
            color: KenColors.ClientRed,
            ':disabled': {
              color: KenColors.ClientRed,
            },
            ':hover': {
              background: KenColors.ClientRed2,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: KenColors.ClientPrimary,
            ':disabled': {
              color: KenColors.ClientPrimary,
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          color: '#373A45',
        },
        colorSecondary: {
          color: '#585A64',
        },
        colorAction: {
          color: '#C33531',
        },
      },
      variants: [
        {
          props: {
            fontSize: 'small',
          },
          style: {
            height: '18px',
            width: '18px',
          },
        },
        {
          props: {
            fontSize: 'medium',
          },
          style: {
            height: '24px',
            width: '24px',
          },
        },
        {
          props: {
            fontSize: 'large',
          },
          style: {
            height: '36px',
            width: '36px',
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        label: {
          padding: '3px 6px',
        },
        colorPrimary: {
          background: KenColors.kenWhite,
          color: KenColors.ClientBlack2,
        },
        colorSecondary: {
          background: KenColors.ClientGrey3,
          color: KenColors.ClientBlack2,
        },
        colorInfo: {
          background: KenColors.ClientBlue4,
          color: KenColors.ClientBlue5,
        },
        colorSuccess: {
          background: KenColors.ClientSuccessGreen,
          color: KenColors.ClientSuccessGreen2,
        },
        colorWarning: {
          background: KenColors.ClientWarningYellow,
          color: KenColors.ClientWarningYellow2,
        },
      },
      variants: [
        {
          props: {
            variant: 'extraSmall',
          },
          style: {
            padding: '0px 0px',
          },
        },
        {
          props: {
            variant: 'small',
          },
          style: {
            padding: '6px 8px',
          },
        },
        {
          props: {
            variant: 'medium',
          },
          style: {
            padding: '4px 12px',
          },
        },
        {
          props: {
            variant: 'large',
          },
          style: {
            padding: '10px 12px',
          },
        },
      ],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const GlobalThemeProvider: React.FunctionComponent<ReactChildren> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default GlobalThemeProvider;
