import { Paper, IconButton, Grid, Typography, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "../../Components/AppLayout/styles";
import KenColors from "../../Helpers/Themes/kenColors";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ProfileImage from "./ProfileImage/profileImage";
import Personaldetails from "./Personaldetails";
import ProfileDetails from "./Profile";
import AddressDetails from "./Address";
import OccupationDetails from "./Occupation";
import { UserProvider } from "../RegisterForm/UserContext";
import { FormProvider, useForm } from 'react-hook-form';
import { getUserDetails } from "../../Utils/apiService";
import Learninginterests from "./Learninginterests";

function Profile() {
    
    const methods = useForm();
    const [userDetails, setUserDetails] = useState<any>(undefined);
    const [retryCount, setRetryCount] = useState<boolean>(false);
    // const maxRetries = 3;
    const [loading, setLoading] = useState(true);

    const fetchUserDetails = async () => {
        try {
            const res: any = await getUserDetails();
            if (res?.data) {
                setUserDetails(res.data);
                setRetryCount(false);
                setLoading(false);
            } else {
                setRetryCount(true);
            }
        } catch (error) {
            console.error("Failed to fetch user details:", error);
            // handleRetry();
        }
    };

    // const handleRetry = () => {
    //     if (retryCount < maxRetries) {
    //         setRetryCount((prevCount: number) => prevCount + 1);
    //     } else {
    //         setUserDetails(null);
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        fetchUserDetails();
    }, [retryCount]);

    // useEffect(() => {
    //     if (userDetails === undefined && retryCount > 0 && retryCount <= maxRetries) {
    //         fetchUserDetails();
    //     }
    // }, [userDetails, retryCount]);
 
    return (
        <UserProvider>
            <Grid style={{ padding: "10px 0px 0px 15px" }} width={"100%"}>
                <Paper style={{ padding: 10, marginLeft: 8, borderRadius: 12, height: 120 }}>
                    <div style={{ padding: 10, display: "flex" }}>
                        <ProfileImage />
                        <div style={{ width: '1px', height: '100px', marginRight: 20, marginLeft: 20, background: KenColors.ClientBlue8 }} />
                        <Grid>
                            <p style={{ fontSize: 20, fontWeight: 500 }}>{userDetails ? userDetails?.full_name : userDetails?.full_name}</p>
                            <p>{userDetails?.current_occupation}</p>
                        </Grid>
                    </div>
                </Paper>
                <Grid style={{ paddingTop: 10, paddingLeft: 10 }}>
                    <Paper style={{ padding: 20, borderRadius: 12 }}>
                        <Grid container>
                            <Grid item sm={12} lg={6} padding={1}>
                                <Personaldetails />
                            </Grid>
                            <Grid item lg={6} sm={12} padding={1}>
                                <ProfileDetails />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={6} sm={12} padding={1}>
                                <AddressDetails />
                            </Grid>
                            <Grid item lg={6} sm={12} padding={1}>
                                <FormProvider {...methods}>
                                    <OccupationDetails />
                                </FormProvider>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={6} sm={12} padding={1} >
                                <Learninginterests />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </UserProvider>
    )
}
export default Profile;