import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: '16px',
  },
}));

export default function KenDialog(props) {
  const {
    open,
    handleClose,
    title,
    dialogText,
    negativeButtonText,
    positiveButtonText,
    dialogContent,
    dialogActions,
    children,
    titleContainerStyles,
    maxWidth,
    dialogActionProps,
    negativeButtonClick,
    positiveButtonClick,
    hidePositiveButton,
    hideNegativeButton,
    onClose,
    minWidth = '326px',
  } = props;

  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: minWidth,
          },
        },
      }}
      maxWidth={maxWidth}
    >
      {title && (
        <DialogTitle id="form-dialog-title" classes={{ root: classes.padding }} style={{ ...titleContainerStyles }}>
          {title}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      {dialogContent || <>{dialogText && <DialogContent classes={{ root: classes.padding }}>{dialogText && <DialogContentText>{dialogText}</DialogContentText>}</DialogContent>}</>}
      <DialogContent classes={{ root: classes.padding }}>{children ? children : null}</DialogContent>
      {dialogActions && (
        <DialogActions {...dialogActionProps}>
          {!hideNegativeButton && (
            <Button
              className="buttonText-medium button-medium"
              variant="text"
              onClick={() => {
                negativeButtonClick();
              }}
            >
              {negativeButtonText || 'Cancel'}
            </Button>
          )}
          {!hidePositiveButton && (
            <Button
              variant="contained"
              className="buttonText-large button-medium"
              onClick={() => {
                positiveButtonClick();
              }}
            >
              {positiveButtonText || 'Ok'}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
