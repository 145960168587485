import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  actionLogo: {
    display: 'flex',
    height: '65px',
    width: '140px',
  },
}));

const globalStyles = `
.carousel .control-dots {
    bottom: -10px !important; /* Adjust this value to move the dots up or down */
    backgroundColor: '#00387A !important';  }
`;

const styleElement = document.createElement('style');
styleElement.innerHTML = globalStyles;
document.head.appendChild(styleElement);
