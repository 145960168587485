import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactChildren } from '../../interfaces/Common';
import Image from '../../Assets/assetHelper';
import KenColors from '../../Helpers/Themes/kenColors';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    overflow: 'auto',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundImage: `url(${Image.Images.AuthLayoutBg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: '60%',
      backgroundColor: theme.palette.KenColors.kenBlack,
    },
    '& > div': {
      position: 'relative',
      zIndex: 1,
      right: 0,
      textAlign: 'center',
      color: theme.palette.KenColors.kenWhite,
    },
  },
  heading: {
    fontSize: '40px !important',
    fontWeight: '600 !important',
    color: theme.palette.KenColors.kenWhite,
    textAlign: 'left',
    width: '100%',
  },
  subHeading: {
    color: theme.palette.KenColors.kenWhite,
    width: '100%',
    textAlign: 'start',
  },
  formContainer: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    alignItems: 'center',
    background: theme.palette.KenColors.ClientLogin,
    padding: '20px 0px',
    overflow: 'auto',
  },
  layoutBar: {
    background: theme.palette.KenColors.ClientBlue6,
    height: '9px',
    width: '98px',
    margin: '10px 0px',
  },
  escetra: {
    height: '8px',
    borderRadius: '8px',
    background: '#C33531',
  },
  boxLayout: {
    marginLeft: '24%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const AuthLayout: React.FunctionComponent<ReactChildren> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={6}>
        <Box component={'div'} className={classes.boxLayout}>
          <Typography className={classes.heading}>Witness the transformative power of Technology</Typography>
          <Box display={'flex'} gap={'4px'} marginTop={'16px'}>
            <Box width={'8px'} className={classes.escetra} />
            <Box width={'45px'} className={classes.escetra} />
            <Box width={'8px'} className={classes.escetra} />
            <Box width={'8px'} className={classes.escetra} />
          </Box>
          <Typography variant="medium" component={'h6'} className={classes.subHeading}>
            Leading the digital transformation narrative for the $250 bn technology industry in India
          </Typography>
          <Typography variant="medium" component={'h6'} className={classes.subHeading} marginTop={'16px'}>
            #ThinkDigitalThinkIndia
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={5} className={classes.formContainer}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
