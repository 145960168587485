import * as React from 'react';
import { Grid, Typography, Divider, Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery, TextField, Button, Snackbar, SnackbarContent, } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useUserContext } from '../../RegisterForm/UserContext';
import KenButton from '../../../GlobalComponents/KenButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from "react-hook-form";
import { onboarding, ResetPassword, sentOtp, VerifyOtp } from "../../../Utils/apiService";
import { REGEX } from "../../../Helpers/constant";
import KenLoader from "../../../GlobalComponents/KenLoader";
import { MuiTelInput } from 'mui-tel-input'
import KenInput from '../../../GlobalComponents/KenInput';
import { useState } from 'react';

type ResetPasswordForm = {
    newPassword: string;
    confirmPassword: string;
};

function ProfileDetails() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarMessageColor, setsnackbarMessageColor] = useState<boolean>(false);
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const userData = useUserContext();
    const [open, setOpen] = React.useState(false);
    const [updateType, setUpdateType] = React.useState<"phone" | "password" | null>(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { control, handleSubmit, getValues, reset } = useForm<ResetPasswordForm>({ mode: "onChange" });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [PhoneNumber, setValue] = React.useState('')
    const [otp, setOtp] = React.useState<any>('')
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const cleanedValue = newValue.replace(/\D/g, '');
        setValue(cleanedValue);
    };

    const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setOtp(newValue);
    };
    const handleClickOpen = (type: "phone" | "password" | null) => {
        setUpdateType(type);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setUpdateType(null);
    };

    const handleResetPassword = (data: ResetPasswordForm) => {
        setLoading(true);
        ResetPassword({ userid: userData?.id, password: data.confirmPassword, method: "POST" })
            .then((res) => {
                if (res?.success) {
                    reset();
                    setsnackbarMessageColor(true)
                    setSnackbarOpen(true);
                    setSnackbarMessage("Password updated successfully");
                } else {
                    setsnackbarMessageColor(false)
                    setSnackbarOpen(true);
                    setSnackbarMessage("Password update failed. Try again");
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };


    const handleSendOtp = async () => {
        try {
            let res = await sentOtp(PhoneNumber);
            if (res.success) {
                setsnackbarMessageColor(true)
                setSnackbarOpen(true);
                setSnackbarMessage("OTP sent successfully");
            }
        } catch (error) {
            setsnackbarMessageColor(false)
            setSnackbarOpen(true);
            setSnackbarMessage("OTP sending failed");
        }
    };

    const mobileVerify = () => {
        const data: any = {
            phone: PhoneNumber,
            otp: otp,
        };
        VerifyOtp(data).then((res) => {
            if (res === true) {
                const userUpdate: any = {
                    method: 'post',
                    users: {
                        id: userData?.id,
                        phone1: PhoneNumber,
                        customfields: [
                        ],
                    },
                };
                onboarding(userUpdate).then((res: any) => {
                    if (res?.status === 200) {
                        setsnackbarMessageColor(true)
                        setSnackbarMessage("OTP verified successfully");
                        window.location.reload();
                    } else {
                        setsnackbarMessageColor(false)
                        setSnackbarMessage("OTP verification failed");
                    }
                });
            } else {
                setsnackbarMessageColor(false)
                setSnackbarMessage('Invalid OTP. Please check the OTP number and try again');
            }

        });
    };
    return (
        <Grid style={{ backgroundColor: "#f6f8fb", padding: 20, borderRadius: 12 }}>
            {loading && <KenLoader />}
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontSize={14} fontWeight={500}>Profile</Typography>
                <KenButton variant="outlined" onClick={() => handleClickOpen(null)} style={{ float: "right", padding: 0 }}>
                    Edit
                </KenButton>
            </Grid>
            <Divider style={{ paddingTop: 10 }} />
            <Grid container paddingTop={1}>
                <Grid item lg={4}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Phone number</Typography>
                    <Typography>{userData?.phone1}</Typography>
                </Grid>
                <Grid item lg={5}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Email ID</Typography>
                    <Typography>{userData?.email}</Typography>
                </Grid>
                <Grid item lg={3}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Password</Typography>
                    <Typography>{"********"}</Typography>
                </Grid>
            </Grid>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    style: {
                        width: '600px',
                        maxWidth: 'none',
                        height: "50vh"
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    {updateType === "phone" ? "Update Phone Number" : updateType === "password" ? "Change Password" : "Edit Personal Details"}
                    <Typography style={{ float: "right", cursor: 'pointer' }}>
                        <CloseIcon onClick={handleClose} />
                    </Typography>
                    <Divider style={{ marginTop: "10px" }} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {updateType === "phone" && (
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <KenInput value={PhoneNumber} onChange={handleChange} />
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={handleSendOtp} color="primary">Send OTP</Button>
                                </Grid>
                                <Grid item>
                                    <KenInput value={otp} onChange={handleOtpChange} />
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={mobileVerify} color="primary">Verify OTP</Button>
                                </Grid>
                            </Grid>
                        )}
                        {updateType === "password" && (
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Controller
                                        name="newPassword"
                                        control={control}
                                        rules={{
                                            required: "Please enter your password",
                                            validate: {
                                                maxLength: (value) => {
                                                    return value.length > 6 || "Password must have 6 characters";
                                                },
                                                shouldContainNumber: (value) => {
                                                    return REGEX.CONTAINNUMBERS.test(value) || "Password must contains numberic values";
                                                },
                                                shouldContainSymbol: (value) => {
                                                    return REGEX.CONTAINSYMBOLS.test(value) || "Password must contains symbols";
                                                },
                                                shouldContainUppercase: (value) => {
                                                    return REGEX.CONTAINUPPERCASE.test(value) || "Password must contains uppercase";
                                                },
                                                shouldContainLowercase: (value) => {
                                                    return REGEX.CONTAINLOWERCASE.test(value) || "Password must contains lowercase";
                                                },
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <Typography>New Password</Typography>
                                                <TextField
                                                    fullWidth
                                                    type="password"
                                                    placeholder="New Password"
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    {...field}
                                                />
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        name="confirmPassword"
                                        control={control}
                                        rules={{
                                            required: "Please re-enter your password",
                                            validate: (value) => {
                                                return getValues("newPassword") === value || "Password did not match";
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <Typography>Confirm Password</Typography>
                                                <TextField
                                                    fullWidth
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    {...field}
                                                />
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleSubmit(handleResetPassword)}>Change Password</Button>
                                </Grid>
                            </Grid>
                        )}
                        {updateType === null && (
                            <Grid container direction="column" spacing={2}>
                                <Grid padding={1} paddingTop={2}>
                                    <Typography>Email ID</Typography>
                                    <div style={{ border: "1px solid black", display: "flex", padding: 8, borderRadius: 5, alignItems: "center" }}>
                                        <Typography>{userData?.email}</Typography>
                                        <button
                                            style={{
                                                marginLeft: "auto",
                                                background: "none",
                                                border: "none",
                                                textDecoration: "underline",
                                                color: "#373A45",
                                                cursor: "not-allowed"
                                            }}
                                            disabled
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </Grid>
                                <Grid padding={1}>
                                    <Typography>Phone number</Typography>
                                    <div style={{ border: "1px solid black", display: "flex", padding: 8, borderRadius: 5, alignItems: "center" }}>
                                        <Typography>{userData?.phone1}</Typography>
                                        <button
                                            style={{
                                                marginLeft: "auto",
                                                background: "none",
                                                border: "none",
                                                textDecoration: "underline",
                                                color: "#373A45",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => handleClickOpen("phone")}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </Grid>
                                <Grid padding={1}>
                                    <Typography>Password</Typography>
                                    <div style={{ border: "1px solid black", display: "flex", padding: 8, borderRadius: 5, alignItems: "center" }}>
                                        <Typography>{"*********"}</Typography>
                                        <button
                                            style={{
                                                marginLeft: "auto",
                                                background: "none",
                                                border: "none",
                                                textDecoration: "underline",
                                                color: "#373A45",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => handleClickOpen("password")}
                                        >
                                            Change
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} message={snackbarMessage}>
                <SnackbarContent sx={{ backgroundColor: snackbarMessageColor === true ? 'green' : 'red' }} message={snackbarMessage} />
            </Snackbar>
        </Grid>
    );
}

export default ProfileDetails;
