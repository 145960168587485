import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from '../../Utils/routes';
import { useAppContext } from '../../Helpers/Contexts/appContext';
import AuthLayout from '../../Components/AuthLayout';
import AppLayout from '../../Components/AppLayout';
import { PrivateRoute, PublicRoute } from '../../Utils/helperFunctions';
import AssesmentsTest from '../Assessments/AssesmentTest';
import TestAssesments from '../Assessments/AssesmentTest/TestAssesments';
import LearningInterest from '../RegisterForm/LearningInterest';
import Profile from '../Profile';
import { getUserDetails } from '../../Utils/apiService';
import { useNavigate } from "react-router-dom";
import KenLoader from '../../GlobalComponents/KenLoader';

const Signup = React.lazy(() => import('../Auth/SignUp/index'));
const VerifyOTP = React.lazy(() => import('../Auth/VerifyOtp/index'));
const EmailConformation = React.lazy(() => import('../Auth/ConfirmEmail/index'));
const Login = React.lazy(() => import('../Auth/Login/index'));
const ForgotPassword = React.lazy(() => import('../Auth/ForgotPassword/index'));
const ConfirmPassword = React.lazy(() => import('../Auth/ConfirmPassword/index'));
const Home = React.lazy(() => import('../Home/index'));
const Courses = React.lazy(() => import('../Courses/index'));
const CourseDetails = React.lazy(() => import('../Courses/CourseDetails/index'));
const Assessment = React.lazy(() => import('../Assessments/index'));
const AssessmentDetails = React.lazy(() => import('../Assessments/AssessmentDetails/index'));
const RegisterForm = React.lazy(() => import('../RegisterForm/index'));

const MainLayout: React.FunctionComponent = () => {
  const { dispatch, state: { config } } = useAppContext();
  const navigate = useNavigate();
  const UserData = localStorage.getItem('userData');
  const userDatas = UserData ? JSON.parse(UserData) : null;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (userDatas?.phone && userDatas.phone.length < 5) {
      getUserDetails().then((res) => {
        const phone = res?.data?.phone1;
        if (!phone || phone.length < 5) {
          navigate(`${routes.register}`);
          setLoading(false);
        }
        setLoading(false);
      }).catch((error) => {
        console.error('Error fetching user details:', error);
      });
    }
    setLoading(false);
  }, [navigate]);

  const [userDetails, setUserDetails] = useState<any>(undefined);
  const [retryCount, setRetryCount] = useState<boolean>(false);
  // const maxRetries = 3;
  const fetchUserDetails = async () => {
    try {
      const res: any = await getUserDetails();
      if (res?.data) {
        setUserDetails(res.data);
        const userData = {
          name: res?.data?.full_name,
          email: res?.data?.email,
          profile_picture: res?.data?.profile_picture,
          user_id: res?.data?.id,
          token: res?.data?.token,
          area_interest: res?.data?.area_interest,
          phone: res?.data?.phone1
        }
        localStorage.setItem("userData", JSON.stringify(userData));
        setRetryCount(false)
        setLoading(false);
      } else {
        setRetryCount(true)
      }
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      // handleRetry();
    }
  };

  // const handleRetry = () => {
  //   if (retryCount < maxRetries) {
  //     setRetryCount((prevCount: number) => prevCount + 1);
  //   } else {
  //     setUserDetails(null);
  //     setLoading(false);
  //   }
  // };
  useEffect(() => {
    fetchUserDetails();
  }, [retryCount]);
  // useEffect(() => {
  //   if(userDatas){
  //     fetchUserDetails();
  //   }
  // }, []);

  // useEffect(() => {
  //   if(userDatas){
  //   if (userDetails === undefined && retryCount > 0 && retryCount <= maxRetries) {
  //     fetchUserDetails();
  //   }
  // }
  // }, [userDetails, retryCount]);

  return (
    <>
      {loading && <KenLoader />}
      <Routes>
        {/* Initial Route */}
        <Route path="/" element={<Navigate to={routes.login} />} />

        {/* Public Routes */}
        <Route
          path={routes.login}
          index
          element={
            <PublicRoute>
              <AuthLayout>
                <Login />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route
          path={routes.otpverification}
          element={
            <PublicRoute>
              <AuthLayout>
                <VerifyOTP />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route
          path={routes.emailverification}
          element={
            <PublicRoute>
              <AuthLayout>
                <EmailConformation />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route
          path={routes.forgotPassword}
          element={
            <PublicRoute>
              <AuthLayout>
                <ForgotPassword />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route
          path={routes.signup}
          element={
            <PublicRoute>
              <AuthLayout>
                <Signup />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route path={routes.register} element={<RegisterForm />} />
        <Route path={routes.learninginterest} element={<LearningInterest data={undefined} userId={0} />} />
        <Route
          path={routes.changePassword}
          element={
            <PublicRoute>
              <AuthLayout>
                <ConfirmPassword />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route path={routes.register} element={<RegisterForm />} />

        {/* Private Routes */}
        {/* Home */}
        <Route
          path={routes.home}
          element={
            <PrivateRoute>
              <AppLayout>
                <Home />
              </AppLayout>
            </PrivateRoute>
          }
        />

        {/* Courses */}
        <Route
          path={routes.courses}
          element={
            <PrivateRoute>
              <AppLayout>
                <Courses />
              </AppLayout>
            </PrivateRoute>
          }
        />

        <Route
          path={`${routes.courses}/:courseid`}
          element={
            <PrivateRoute>
              <AppLayout>
                <CourseDetails />
              </AppLayout>
            </PrivateRoute>
          }
        />

        {/* Assessments */}
        <Route
          path={routes.Assessments}
          element={
            <PrivateRoute>
              <AppLayout>
                <Assessment />
              </AppLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${routes.Assessments}/:assessmentid`}
          element={
            <PrivateRoute>
              <AppLayout>
                <AssessmentDetails />
              </AppLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${routes.profile}`}
          element={
            <PrivateRoute>
              <AppLayout>
                <Profile />
              </AppLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={routes.Assesmentstest}
          element={
            <PrivateRoute>
              <AssesmentsTest />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.TestAssesments}
          element={
            <PrivateRoute>
              <TestAssesments />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default MainLayout;
