import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paper: {
    borderRadius: '12px',
    height: '64vh',
    width: '75vw',
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px',
    marginLeft: '40px',
    [theme.breakpoints.down('md')]: {
      margin: '15px',
      marginLeft: '10px',
    },
  },
  img: {
    width: '108.62px',
    height: '18.1px',
  },
  setupProfile: {
    fontSize: '28px',
    fontWeight: '500',
  },
  divider: {
    background: '#E6E6E8 !important',
  },
  bannerHead: {
    fontSize: '28px !important',
    fontWeight: '500 !important',
    color: '#373A45',
  },
}));
