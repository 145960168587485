import * as React from 'react';
import {
    Grid,
    Typography,
    Divider,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    Radio,
    RadioGroup
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useUserContext } from '../../RegisterForm/UserContext';
import KenButton from '../../../GlobalComponents/KenButton';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import KenInput from '../../../GlobalComponents/KenInput';
import KenTextLabel from '../../../GlobalComponents/KenTextLabel';
import { useForm, Controller } from 'react-hook-form';
import KenColors from '../../../Helpers/Themes/kenColors';
import { onboarding } from '../../../Utils/apiService';

function Personaldetails() {
    const userData = useUserContext();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const UserData = localStorage.getItem("userData");
    const userDatas = UserData ? JSON.parse(UserData) : null;
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const convertTimestampToDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const { control, handleSubmit } = useForm({
        defaultValues: {
            full_name: userData?.full_name || '',
            dob: userData?.dob ? convertTimestampToDate(userData.dob) : '',
            gender: userData?.gender || ''
        }
    });
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const formattedDOB = userData?.dob ? convertTimestampToDate(userData.dob) : '';
    const onSubmit = (data: any) => {
        const userUpdate: any = {
            method: 'post',
            users: {
                id: userDatas?.user_id,
                customfields: [
                    {
                        type: 'gender',
                        value: data?.gender,
                    },
                    // {
                    //   type: 'dob',
                    //   value: data?.dob,
                    // },
                ],

            },
        };
        onboarding(userUpdate).then((res: any) => { })
        handleClose();
    };
    return (
        <Grid style={{ backgroundColor: "#f6f8fb", padding: 20 ,borderRadius:12}}>
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontSize={14} fontWeight={500}>Personal details</Typography>
                <KenButton variant="outlined" onClick={handleClickOpen} style={{ float: "right", padding: 0 }}>
                    Edit
                </KenButton>
            </Grid>
            <Divider style={{ paddingTop: 10 }} />
            <Grid container paddingTop={1}>
                <Grid item lg={4}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Full name</Typography>
                    <Typography>{userData?.full_name}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Date of birth</Typography>
                    <Typography>{formattedDOB}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Gender</Typography>
                    <Typography>{userData?.gender}</Typography>
                </Grid>
            </Grid>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    style: {
                        width: '600px',
                        maxWidth: 'none',
                        height: "50vh"
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Edit Personal Details"}
                    <Typography style={{ float: "right" }}>
                        <CloseIcon onClick={handleClose} />
                    </Typography>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid>
                            <Controller
                                name="full_name"
                                control={control}
                                render={({ field }) => (
                                    <KenInput
                                        label={"Full name"}
                                        required={true}
                                        placeholder="Full name"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="dob"
                                control={control}
                                render={({ field }) => (
                                    <KenInput
                                        label={"Date of Birth"}
                                        required={true}
                                        placeholder=""
                                        {...field}
                                    />
                                )}
                            />
                            <KenTextLabel
                                label={"Gender"}
                                required={true}
                            />
                            <Controller
                                name="gender"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field}>
                                        <Box display={"flex"} gap={"24px"} alignItems={"center"}>
                                            {["Male", "Female", "Others"].map((items, index) => (
                                                <Box display={"flex"} alignItems={"center"} key={index}>
                                                    <Radio value={items} size="medium" />
                                                    <Typography color={KenColors.ClientGrey} component={"label"} variant="medium">
                                                        {items}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button type="submit" autoFocus>
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

export default Personaldetails;
