import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { BASEURL, ONBOARDINGURL } from '../Helpers/constant';
import { LoginData, VerifySignUpFormData } from '../interfaces/Auth';
import { EventFormValues, RegisterEvent } from '../interfaces/Events';
import { PaymentData } from '../interfaces/Common';
import { ApplyJob, FormPostJob } from '../interfaces/Jobs';

export let axiosInstance: AxiosInstance = axios.create({
  baseURL: BASEURL,
  timeout: 15000,
});

axiosInstance.interceptors.request.use((req: AxiosRequestConfig): any => {
  req.headers = req.headers || {};
  const userDataString: any = localStorage.getItem('userData');
  if (userDataString) {
    const userData = JSON.parse(userDataString);
    req.headers['Authorization'] = userData?.token;
  }
  req.headers['Content-Type'] = 'application/json';
  req.headers['Accept'] = 'application/json';
  return req;
});

export const authLogin = async (data: LoginData) => {
  try {
    const path = `/moodle/proxy?endpoint=webservice/restful/server.php/user_login`;
    const axiosInstance = axios.create({
      baseURL: ONBOARDINGURL,
      timeout: 15000,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const res: any = await axiosInstance.post(path, data, config);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getLearningInterests = async () => {
  try {
    const path = `/moodle/proxy?endpoint=webservice/restful/server.php/get_categories`;
    const axiosInstance = axios.create({
      baseURL: ONBOARDINGURL,
      timeout: 15000,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const res: any = await axiosInstance.post(path, {
      "method": "post"
  }, config);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const onboarding = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/update_user`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    console.log(error)
  }
};

export const getUserDetails = async () => {
  try {
    const path = `/webservice/restful/server.php/local_user_get_details`;
    const res = await axiosInstance.post(path, {
      "method": "post"
  });
    return res && res.data ? res.data : res;
  } catch (error) {
    console.log(error)
  }
};

export const getQuizInstruction = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/module_get_quiz_instruction`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    console.log(error)
  }
};

export const signup = async (data: any) => {
  try {
    const path = `/moodle/proxy?endpoint=webservice/restful/server.php/create_user`;
    const axiosInstance = axios.create({
      baseURL: ONBOARDINGURL,
      timeout: 15000,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const res: any = await axiosInstance.post(path,data, config);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const ResetPassword = async (data: any) => {
  try {
    const path = `/moodle/proxy?endpoint=webservice/restful/server.php/request_password_reset`;
    const axiosInstance = axios.create({
      baseURL: ONBOARDINGURL,
      timeout: 15000,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const res: any = await axiosInstance.post(path,data, config);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};
export const getCourseStructure = async (data: any) => {
  try {
    const path = `/moodle/proxy?endpoint=webservice/restful/server.php/get_course_structure`;
    const axiosInstance = axios.create({
      baseURL: ONBOARDINGURL,
      timeout: 15000,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const res: any = await axiosInstance.post(path,data, config);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};
export const courseComplionUpdate = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/view_module`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};
export const sendWelcomeEmail = async (email: any) => {
  try {
    const response = await axios.post(ONBOARDINGURL + `/send/welcome/email?email=${email}`);
    return response.data;
  } catch (error) {
    console.error('Error sending welcome email:', error);
    throw error;
  }
};

export const sentOtp = async (phone: string) => {
  try {
    const response = await axios.post(ONBOARDINGURL + `/send/otp?phone=91${phone}`);
    return response.data;
  } catch (error) {
    console.error('Error sending welcome email:', error);
    // throw error;
  }
};
export const sentEmailOtp = async (email: any, checkIfUserExists: any = false) => {
  try {
    const response = await axios.post(ONBOARDINGURL + `/send/otp?email=${email}`, { checkIfUserExists : checkIfUserExists }, {
      validateStatus : function(status) {
        return true
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error sending welcome email:', error);
     return error
    // throw error;
  }
};

export const VerifyOtp = async (data:any) => {
  try {
    const response = await axios.get(ONBOARDINGURL + `/verify/otp?phone=91${data?.phone}&otp=${data?.otp}`);
    return response.data;
  } catch (error) {
    console.error('Error sending welcome email:', error);
    throw error;
  }
};

export const VerifyEmailOtp = async (email: any, Otp: number) => {
  try {
    const response = await axios.get(ONBOARDINGURL + `/verify/otp?email=${email}&otp=${Otp}`);
    return response.data;
  } catch (error) {
    console.error('Error sending welcome email:', error);
    // throw error;
  }
};


export const initiateTransaction = async (data: any) => {
  try {
    const response = await axios.post(ONBOARDINGURL + `/initiate/transaction`, data);
    return response.data;
  } catch (error) {
    console.error('Error sending welcome email:', error);
    // throw error;
  }

}

export const ForgotPasswordVerifyOtp = async (email: any, Otp: number) => {
  try {
    const response = await axios.get(ONBOARDINGURL + `/verify/otp?email=${email}&otp=${Otp}&checkIfUserExists=true`);
    return response.data;
  } catch (error) {
    console.error('Error sending welcome email:', error);
    // throw error;
  }
};

export const getAllCourse = async () => {
  try {
    const path = `/webservice/restful/server.php/get_courses_by_field`;
    const res = await axiosInstance.post(path, {
      method: 'post',
    });
    return res && res.data ? res?.data : res;
  } catch (error) {
    return error;
  }
};

export const getRecommendedCourses = async (id:any) => {
  try {
    const path = `/webservice/restful/server.php/recommended_courses`;
    const res = await axiosInstance.post(path, {
      method: 'post',
      interests: id,
    });
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getEnrolledCourse = async () => {
  try {
    const path = `/webservice/restful/server.php/enrolled_courses`;
    const res = await axiosInstance.post(path, {
      method: 'post',
    });
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getCourse= async (data:any) => {
  try {
    const path = `/webservice/restful/server.php/get_course`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getCourseGuidebooks = async (data: any) => {
  try {
    const path = `/moodle/proxy?endpoint=webservice/restful/server.php/get_course_guidebooks`;
    const axiosInstance = axios.create({
      baseURL: ONBOARDINGURL,
      timeout: 15000,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const res: any = await axiosInstance.post(path,data, config);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};


export const enrollCourse = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/user_enrol`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getCourseContents = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/get_course_contents`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const quizAccessInformation = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/module_get_quiz_access_information`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const quizStartAttempt = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/quiz_start_attempt`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const answerSubmission = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/module_process_attempt`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};



export const getUserAttempts = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/module_get_user_attempts`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getAttemptData = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/module_get_attempt_data`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getAttemptReview = async (data: any) => {
  try {
    const path = `/webservice/restful/server.php/module_get_attempt_review`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getOtp = async (email: string, reset: boolean) => {
  try {
    const path = `/v1/auth/send/otp?email=${email}&update=${reset}`;
    const res = await axiosInstance.post(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const verifyOtp = async (data: VerifySignUpFormData) => {
  try {
    const path = `/v1/auth/verify/otp`;
    const res = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const verifyOtpReset = async (email: string, otp: string) => {
  try {
    const path = `/v1/auth/verify/otpwithContact?email=${email}&otp=${otp}`;
    const res = await axiosInstance.post(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const loginSSO = async (email: string) => {
  try {
    const path = `/v1/auth/sso/user/verify?email=${email}`;
    const response = axiosInstance.get(path);
    return response;
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (data: LoginData) => {
  try {
    const path = `/v1/auth/login/reset`;
    const res: any = await axiosInstance.post(path, data);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getEvents = async (contactId: string) => {
  try {
    const path = `/v1/event/fetch/user/events?contactId=${contactId}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getEvent = async (id: string) => {
  try {
    const path = `/v1/event/fetch/event/ById?eventId=${id}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getFileUrl = async (data: File) => {
  try {
    const path = `/v1/event/create/publicUrl`;
    const form = new FormData();
    form.append('file', data);
    const res: any = await axiosInstance.post(path, form);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const hostEvent = async (payload: EventFormValues) => {
  try {
    const path = `/v1/event/create/event/schedule`;
    const res: any = await axiosInstance.post(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const registerEvent = async (payload: RegisterEvent) => {
  try {
    const path = `/v1/event/new/registration`;
    const res: any = await axiosInstance.post(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const eventPayment = async (payload: PaymentData) => {
  try {
    const path = `/v1/payment/generate/payment`;
    const res: any = await axiosInstance.post(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const cancelEvent = async (id: string) => {
  try {
    const path = `/v1/event/cancel/registration?eventRegistrationId=${id}`;
    const res: any = await axiosInstance.delete(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getOptions = async (objectName: string, fieldName: string) => {
  try {
    const path = `/v1/helper/getDropDownValues?objectName=${objectName}&fieldName=${fieldName}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getAllJobs = async (conatctId: string) => {
  try {
    const path = `/v1/job/registred/job?contactId=${conatctId}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const getJob = async (jobId: string) => {
  try {
    const path = `/v1/job/individual/job?jobId=${jobId}`;
    const res: any = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const jobApply = async (payload: ApplyJob) => {
  try {
    const path = `/v1/job/apply/job`;
    const res: any = await axiosInstance.post(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const postJob = async (payload: FormPostJob) => {
  try {
    const path = `/v1/job/post/job`;
    const res: any = await axiosInstance.post(path, payload);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};
