import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, Select, Typography, useMediaQuery, useTheme, Snackbar, SnackbarContent } from '@mui/material';
import React, { useState, useEffect } from 'react';
import image1 from '../../Assets/Rectangle 3463894.png';
import { onboarding } from '../../Utils/apiService';
import { useStyles } from './style';
import { getLearningInterests } from '../../Utils/apiService';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppFormContext } from '../../Helpers/Contexts/formContext';
import { useNavigate } from "react-router-dom";
interface Record {
  id: number;
  name: string;
  image: string;
}
interface LearningInterestProps {
  data: any;
  userId: any;
}

const LearningInterest: React.FC<LearningInterestProps> = (props) => {
  const { data, userId } = props;
  const UserData = localStorage.getItem('userData');
  const userDatas = UserData ? JSON.parse(UserData) : null;
  const navigate = useNavigate();
  const { state, dispatch } = useAppFormContext();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const method = useForm({ mode: 'onChange' });
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarMessageColor, setsnackbarMessageColor] = useState<boolean>(false);
  const [records, setRecords] = useState<Record[]>([]);

  useEffect(() => {
    getLearningInterests()
      .then((res: any) => {
        const apiRecords: Record[] = res.data.map((item: any) => ({
          id: item.id,
          name: item.name,
          image: image1,
        }));
        setRecords(apiRecords);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleCheckboxChange = (id: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selectedItems.length < 3) {
        setSelectedItems((prevSelected) => [...prevSelected, id]);
      }
    } else {
      setSelectedItems((prevSelected) => prevSelected.filter((item) => item !== id));
    }
  };
  const onboardings = () => {
    localStorage.removeItem("mobile");
    const userUpdate: any = {
      method: 'post',
      users: {
        id: userDatas?.user_id,
        filename: data?.profile[0]?.name,
        profile_picture: state?.files?.profile,
        phone1: data?.otp_phone,
        country: data?.country,
        customfields: [
          {
            type: 'gender',
            value: data?.gender,
          },
          {
            type: 'pincode',
            value: data?.pincode ? data?.pincode : '',
          },
          {
            type: 'current_occupation',
            value: data?.currentOccupation ? data?.currentOccupation : '',
          },
          data?.currentOccupation === "Working Professional" ?
            {
              type: 'current_employment',
              value: data?.currentEmployment ? data?.currentEmployment : '',
            } : {
              type: 'current_employment',
              value: '-',
            },
          data?.currentOccupation === "Unemployed" ?
            {
              type: 'appropriate_option',
              value: data?.mostAppropriate ? data?.mostAppropriate : '',
            } : {
              type: 'appropriate_option',
              value: '-',
            },
          data?.currentOccupation === "Unemployed" ?
            {
              type: 'past_employment',
              value: data?.PastEmploymentDetails ? data?.PastEmploymentDetails : '',
            } : {
              type: 'past_employment',
              value: '-',
            },
          data?.currentOccupation === "Student" ?
            {
              type: 'student_professional',
              value: data?.studentProfessional ? data?.studentProfessional : '',
            } : {
              type: 'student_professional',
              value: '-',
            },
          {
            type: 'dob',
            value: data?.year,
          },
          {
            type: 'state',
            value: data?.state ? data?.state : '',
          },
          {
            type: 'city',
            value: data?.city ? data?.city : '',
          },
        ],
        interests: selectedItems.join(','),
      },
    };
    onboarding(userUpdate).then((res: any) => {
      if (res?.status === 200) {
        const userData = {
          name: res?.data?.fullname,
          email: res?.data?.email,
          profile_picture: res?.data?.profile_picture,
          user_id: res?.data?.id,
          token: res?.data?.token,
          interests: res?.data?.area_interest || [],
          phone:res?.data?.phone1
        }
        localStorage.setItem("userData", JSON.stringify(userData));
        navigate('/home')
      } else {
        setSnackbarMessage('Something went wrong.');
      }
    });
  };
  const skipInterests=()=>{
    navigate('/home')
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    // if (snackbarMessageColor === true) {
    //   navigate(`${routes.courses}`)
    // }
  };

  return (
    <Box sx={{ position: 'relative', height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        component={'form'}
        height={'80vh'}
        width={isSmallerScreen ? '85vw' : '85vw'}
        sx={{ background: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '30px 0px 0px 0px', borderRadius: '12px', position: 'relative' }}
      >
        <div style={{ margin: '30px', height: 'calc(100% - 120px)', overflowY: 'auto' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight :'10px'
            }}
          >
            <Typography variant="extraverylargebold">Learning Interests</Typography>
            <Typography variant="mediumthin" style={{ marginTop: '10px' }}>
              Select any 3 knowledge areas of your interest
            </Typography>
            <Grid container spacing={2}>
              {records.map((record: any) => (
                <Grid item key={record.id} xl={2} lg={3} md={3} style={{ marginTop: '20px' }}>
                  <Box style={{ backgroundColor: '#F6F8FB', borderRadius: '12px', border: selectedItems.includes(record.name) ? '1px solid #1976D2' : 'none' }}>
                    <div style={{ margin: '10px' }}>
                      <img src={record.image} alt={record.name} style={{ width: '100%', marginTop: '10px' }} />
                      <div style={{ display: 'flex' }}>
                        <FormControlLabel
                          style={{ marginBottom: '30px' }}
                          control={<Checkbox checked={selectedItems.includes(record.id)} onChange={handleCheckboxChange(record.id)} color="primary" />}
                          label={record.name}
                        />
                      </div>
                    </div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            background: '#FFFFFF',
            boxShadow: '0px -4px 44px 0px #BCBCBC1A',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '12px 0px',
            width: '100%',
            zIndex: 1,
          }}
        >
          <Typography variant="medium" style={{ marginRight: '40px' }} onClick={skipInterests}>
            Skip
          </Typography>
          <Button className="button-medium" variant="contained" style={{ marginRight: '40px' }} onClick={onboardings}>
            Finish setup
          </Button>
        </div>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      >
        <SnackbarContent sx={{ backgroundColor: snackbarMessageColor === true ? "green" : "red" }} message={snackbarMessage} />
      </Snackbar>
    </Box>
  );
};

export default LearningInterest;
