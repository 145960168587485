import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Divider, Grid, Typography, useMediaQuery, useTheme, Snackbar, SnackbarContent } from '@mui/material';
import routes from '../../../Utils/routes';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CircularProgressChartjs from "./CircularProgressChartjs";
import CircleIcon from '@mui/icons-material/Circle';
import KenButton from '../../../GlobalComponents/KenButton';
import KenDialog from '../../../GlobalComponents/KenDialog';
import CloseIcon from '@mui/icons-material/Close';
import clock from '../../../Assets/clock.png';
import SchoolIcon from '@mui/icons-material/School';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getAttemptReview, answerSubmission } from '../../../Utils/apiService';
import { useLocation } from 'react-router-dom';
import { margin } from '@mui/system';
interface TestSummaryProps {
    cartdatas: any;
    goBackToTest: () => void;
    summaryData: any
    remainingSeconds: any
    remainingMinutes: any
    remainingHours: any
}
const TestSummary: React.FC<TestSummaryProps> = ({ cartdatas, goBackToTest, summaryData, remainingMinutes, remainingSeconds, remainingHours }) => {
    const cartdata = cartdatas;
    const summary: any = summaryData;


    const [correctCount, setCorrectCount] = useState(0);
    const [Question_count, setQuestion_count] = useState("");
    const formatTimeUnit = (unit: number) => unit.toString().padStart(2, '0');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const quizId = queryParams.get('quizId');
    const [isDialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('xl'));
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [Percentage, setPercentage] = useState("")
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const handleOpenDialog = () => {
        const data = {
            method: "post",
            attemptid: quizId,
            page: -1
        };
        getAttemptReview(data).then((res) => {
            setPercentage(res?.data?.percent)
            setQuestion_count(res?.data?.question_count)
            const questions = res?.data?.questions || [];
            const correctStatusCount = questions.reduce((count: number, question: { status: string; }) => {
                return question.status === "Correct" ? count + 1 : count;
            }, 0);
            setCorrectCount(correctStatusCount);
            setDialogOpen(true);
        });

    };
    const [SubmitTest, setSubmitTest] = useState<boolean>(false)
    const Submit = () => {
        const data = {
            "method": "post",
            "attemptid": quizId,
            "data": [
            ],
            "finishattempt": true,
            "preflightdata": []
        };
        answerSubmission(data).then((res) => {
            setSubmitTest(true)
            setSnackbarOpen(true);
            setSnackbarMessage('Quiz submited successfully.');
        });

    }
    return (
        <>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // height: '100vh',

                }}
            >
                <Card style={{ width: '90%', minHeight: '80vh', display: 'flex', flexDirection: 'column', borderRadius: '24px', marginTop: 20 }}>
                    <Card style={{ height: '75vh' }} variant="scrollContained" className="scrollbar-cus">
                        <Grid container >
                            <Grid item md={6} lg={6} xl={6} padding={10}>
                                <Typography variant="largemedium">Finish test?</Typography>
                                <Typography paddingTop={1}>Your test summary</Typography>
                                <Grid marginTop={2}>
                                    <div className="w-3/6 bg-gray-100 p-4 rounded">
                                        <CircularProgressChartjs attempted={cartdata?.Attempted} unattempted={cartdata?.Unattempted} revisit={cartdata?.revisitLater} answeredAndFlagged={cartdata?.answeredAndFlagged} />
                                    </div>
                                </Grid>
                                <Grid container marginTop={3}>
                                    <Grid lg={6}>
                                        <Box display="flex" alignItems="center" marginTop={1}>
                                            <CircleIcon style={{ color: "#7AB37C" }} />
                                            <Box ml={1}>Attempted: {cartdata?.Attempted}</Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" marginTop={1}>
                                            <CircleIcon style={{ color: "#FCD572" }} />
                                            <Box ml={1}>Marked for revisit: {cartdata?.revisitLater}</Box>
                                        </Box>
                                    </Grid>
                                    <Grid lg={6}>
                                        <Box display="flex" alignItems="center" marginTop={1}>
                                            <CircleIcon style={{ color: "#585A64" }} />
                                            <Box ml={1}>Un Attempted: {cartdata?.Unattempted}</Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" marginTop={1}>
                                            <CircleIcon style={{ color: "#7495FF" }} />
                                            <Box ml={1}>Answered & Flagged: {cartdata?.answeredAndFlagged}</Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider sx={{ margin: '30px' }} orientation="vertical" flexItem />
                            <Grid item md={5} lg={5} xl={5}>
                                <div style={{ margin: '7px', height: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Card style={{ width: '39%', display: 'flex', flexDirection: 'column', borderRadius: '24px', padding: '20px', marginLeft: 'auto', marginRight: '-57px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div>
                                                    <Typography variant="mediumthin">Test Timer</Typography>
                                                    <div style={{ fontSize: '20px', marginTop: '15px' }}>
                                                        {/* Display remaining time */}
                                                        <span style={{ backgroundColor: '#E6E6E8', padding: '8px', borderRadius: '8px' }}>{formatTimeUnit(remainingHours)}</span>
                                                        <span style={{ fontSize: '24px', marginLeft: '5px' }}>:</span>
                                                        <span style={{ backgroundColor: '#E6E6E8', padding: '8px', borderRadius: '8px', marginLeft: '10px' }}>{formatTimeUnit(remainingMinutes)}</span>
                                                        <span style={{ fontSize: '24px', marginLeft: '5px' }}>:</span>
                                                        <span style={{ backgroundColor: '#E6E6E8', padding: '8px', borderRadius: '8px', marginLeft: '10px' }}>{formatTimeUnit(remainingSeconds)}</span>
                                                    </div>
                                                </div>
                                                {!isSmallerScreen && (
                                                    <img src={clock} style={{ marginTop: '10px', height: '66px', width: '57px' }} />
                                                )}
                                            </div>
                                        </Card>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="largemedium">Section wise summary</Typography>

                                        </div>
                                        <div style={{ marginTop: '40px', margin: '20px', border: '1px solid #E6E6E8', borderRadius: '12px', width: "100%" }}>
                                            <table style={{ borderCollapse: 'collapse', margin: '20px', padding: '10px', width: isSmallerScreen ? '95%' : '27vw' }}>
                                                <thead>
                                                    <tr style={{ textAlign: 'left', fontSize: '16px', color: '#581212', fontWeight: 500, borderBottom: '1px solid #E6E6E8' }}>
                                                        <th style={{ fontWeight: 500, padding: "0px 20px 10px 20px" }}>#</th>
                                                        <th style={{ fontWeight: 500, padding: "0px 20px 10px 20px" }}>Section name</th>
                                                        <th style={{ fontWeight: 500, padding: "0px 20px 10px 20px" }}>Answered</th>
                                                        <th style={{ fontWeight: 500, padding: "0px 20px 10px 20px" }}>Unanswered</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {summary.map((section: any, index: any) => (
                                                        <tr key={index}>
                                                            <td style={{ padding: '10px' }}>
                                                                <div style={{ margin: '10px' }}>{index + 1}</div>
                                                            </td>
                                                            <td style={{ padding: '10px' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <label htmlFor={`section-${index}`} style={{ marginLeft: '10px' }}>
                                                                        {section.section}
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td style={{ padding: '10px' }}>
                                                                <div style={{ margin: '10px' }}>{section.answeredQuestions}</div>
                                                            </td>
                                                            <td style={{ padding: '10px' }}>
                                                                <div style={{ margin: '10px' }}>{section.totalQuestions - section.answeredQuestions}</div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                    <div style={{ bottom: '0', background: '#FFFFFF', boxShadow: '0px -4px 44px 0px #BCBCBC1A', display: 'flex', justifyContent: 'end', padding: '18px 24px', position: 'relative', zIndex: '0' }}>
                        {SubmitTest === false ?
                            <Button
                                className="buttonmedium"
                                style={{
                                    background: '#E8EBF3',
                                    color: 'black',
                                    marginRight: 10,
                                    marginLeft: 10
                                }}
                                variant="contained"
                                onClick={goBackToTest}
                            >
                                Go back to test
                            </Button> : null}
                        {SubmitTest === false ?
                            <Button
                                className="buttonmedium"
                                style={{
                                    background: 'linear-gradient(90deg, #581212 -92.71%, #C33531 100%)',
                                    color: 'white',
                                }}
                                variant="contained"
                                onClick={
                                    Submit
                                }
                            >
                                Submit
                            </Button>
                            :
                            <Button
                                className="buttonmedium"
                                style={{
                                    background: 'linear-gradient(90deg, #581212 -92.71%, #C33531 100%)',
                                    color: 'white',
                                }}
                                variant="contained"
                                onClick={
                                    handleOpenDialog
                                }
                            >
                                Get scores
                            </Button>
                        }
                    </div>
                </Card>
            </Box>
            <KenDialog open={isDialogOpen} minWidth={'100%'}>
                <Box minWidth={'548px'}>
                    <Grid container style={{ display: 'flex', padding: '10px', borderRadius: '12px', alignItems: 'center' }}>
                        <Grid lg={11}>Total marks </Grid>
                        <Grid lg={1}><CloseIcon onClick={handleCloseDialog} style={{ height: 30, width: 30, color: "#585A64" }} /></Grid>
                    </Grid>
                    <Divider />
                    <Grid container style={{ display: 'flex', padding: '10px', borderRadius: '12px', alignItems: 'center' }}>
                        <Grid lg={6} padding={1}>
                            <Grid container style={{ display: 'flex', padding: '10px', borderRadius: '12px', alignItems: 'center', backgroundColor: "#F6F8FB" }}>
                                <Grid><SchoolIcon style={{ height: 30, width: 30, color: "#585A64", padding: 10 }} /></Grid>
                                <Grid marginTop={2}>
                                    <Grid>
                                        Percentage
                                    </Grid>
                                    <Grid>
                                        <b style={{ color: "#388E3C" }}>{Percentage}%</b>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid lg={6} padding={1}>
                            <Grid container style={{ display: 'flex', padding: '10px', borderRadius: '12px', alignItems: 'center', backgroundColor: "#F6F8FB" }}>
                                <Grid><CheckCircleIcon style={{ height: 30, width: 30, color: "#585A64", padding: 10 }} /></Grid>
                                <Grid marginTop={2}>
                                    <Grid>
                                        Right answers
                                    </Grid>
                                    <Grid>
                                        <b>{correctCount}/{Question_count}</b>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex', marginTop: '10px', padding: "0px 20px 10px 20px" }}>
                        <KenButton
                            variant="primary"
                            style={{ width: "50%", background: '#E8EBF3', borderRadius: '8px', marginRight: 20, color: "black", padding: 10 }}
                            onClick={() => {
                                navigate(`${routes.home}`)
                            }}
                        >
                            Retake test
                        </KenButton>
                        <KenButton variant="primary" style={{ width: "50%", background: 'linear-gradient(90deg, #581212 -92.71%, #C33531 100%)', borderRadius: '8px', padding: 10 }}
                            onClick={() => {
                                navigate(`${routes.home}`)
                            }}

                        >
                            Finish test
                        </KenButton>
                    </div>
                </Box>
            </KenDialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            >
                <SnackbarContent sx={{ backgroundColor: "green" }} message={snackbarMessage} />
            </Snackbar>
        </>
    );
};

export default TestSummary;
