import * as React from 'react';
import { Box, Divider, Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, Checkbox, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useUserContext } from '../../RegisterForm/UserContext';
import KenButton from '../../../GlobalComponents/KenButton';
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { registerVerificationForm } from '../../../Utils/FormJsons/registerVerificationForm';
import { getUserDetails, onboarding } from '../../../Utils/apiService';
function OccupationDetails() {
    const userData = useUserContext();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
    const [userDetails, setUserDetails] = React.useState<any>();
    React.useEffect(() => {
        getUserDetails().then((res: any) => {
            setUserDetails(res?.data);
        });
    }, []);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const { control, handleSubmit } = useFormContext();
    const currentOccupation = useWatch({ control, name: 'currentOccupation' });
    const [checked, setChecked] = React.useState(false);
    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };
    const RenderFields = ({ items }: any) => {
        switch (items.type) {
            case 'select':
                return (
                    <Box>
                        <Controller
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel>{items.label}</InputLabel>
                                    <Select
                                        {...field}
                                        label={items.label}
                                    >
                                        {items.options.map((option: any, index: number) => (
                                            <MenuItem key={index} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            name={items.name}
                            control={control}
                            rules={{ ...items.validation }}
                        />
                    </Box>
                );
            case 'checkbox':
                return (
                    <Box display={'flex'} gap={'14px'} alignItems={'center'}>
                        <Checkbox id="occupation_check" />
                        <Typography htmlFor="occupation_check" variant="mediumthin" component={'label'}>
                            {items.label}
                        </Typography>
                    </Box>
                );
            default:
                return null;
        }
    };
    const onSubmit = (data: any) => {
        const userUpdate: any = {
            method: 'post',
            users: {
                id: userDetails?.id,
                country: "India",
                customfields: [
                    {
                        type: 'current_occupation',
                        value: data?.currentOccupation ? data?.currentOccupation : '',
                    },
                    data?.currentOccupation === "Working Professional" ?
                        {
                            type: 'current_employment',
                            value: data?.currentEmployment ? data?.currentEmployment : '',
                        } : {
                            type: 'current_employment',
                            value: '-',
                        },
                    data?.currentOccupation === "Unemployed" ?
                        {
                            type: 'appropriate_option',
                            value: data?.mostAppropriate ? data?.mostAppropriate : '',
                        } : {
                            type: 'appropriate_option',
                            value: '-',
                        },
                    data?.currentOccupation === "Unemployed" ?
                        {
                            type: 'past_employment',
                            value: data?.PastEmploymentDetails ? data?.PastEmploymentDetails : '',
                        } : {
                            type: 'past_employment',
                            value: '-',
                        },
                    data?.currentOccupation === "Student" ?
                        {
                            type: 'student_professional',
                            value: data?.studentProfessional ? data?.studentProfessional : '',
                        } : {
                            type: 'student_professional',
                            value: '-',
                        },
                ],
            },
        };
        onboarding(userUpdate).then((res: any) => { })
        handleClose();
    };
    return (
        <Grid style={{ backgroundColor: "#f6f8fb", padding: 20, borderRadius: 12 }}>
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontSize={14} fontWeight={500}>Occupation</Typography>
                <KenButton variant="outlined" onClick={handleClickOpen} style={{ float: "right", padding: 0 }}>
                    Edit
                </KenButton>
            </Grid>
            <Divider style={{ paddingTop: 10 }} />
            <Grid container paddingTop={1}>
                <Grid item lg={5}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Current occupation</Typography>
                    <Typography>{userData?.current_occupation}</Typography>
                </Grid>
                <Grid item lg={5}>
                    <Typography color={"#8C8E94"} fontSize={14} fontWeight={500}>Current employment status</Typography>
                    <Typography>{userData?.current_employment ? userData?.current_employment : "-"}</Typography>
                </Grid>
            </Grid>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    style: {
                        width: '600px',
                        maxWidth: 'none',
                        height: "70vh",
                        overflow: 'hidden'
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Edit Occupation Details"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update your occupation details and provide additional information if required.
                    </DialogContentText>
                    <Box sx={{ height: '60vh', overflow: 'auto', padding: '20px 0' }}>
                        <Box sx={{ height: '53vh', padding: '20px 15px' }} component={'div'} className="scrollbar-cus">
                            {registerVerificationForm[2].sections.map((section: any, index) => (
                                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                    <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
                                        {section.fields.map((field: any, idx: any) => {
                                            if (field.name === 'currentOccupation' || (field.rule?.scope === 'currentOccupation' && field.rule.value === currentOccupation)) {
                                                return <RenderFields key={idx} items={field} />;
                                            }
                                            return null;
                                        })}
                                    </Box>
                                </Box>
                            ))}
                            {currentOccupation === 'Student' && (
                                <Grid container spacing={2} style={{ marginTop: 20 }}>
                                    <Grid item xs={1} style={{ paddingTop: "10px" }}>
                                        <Controller
                                            render={({ field }) => (
                                                <Checkbox
                                                    checked={checked}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    onChange={(date) => {
                                                        handleChangeCheckbox(date);
                                                        field.onChange(date);
                                                    }}
                                                />
                                            )}
                                            name="checked"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={11}>
                                        Please select if you have been offered employment but are yet to take up a job OR are undergoing/select for internship & Apprenticeship roles in IT or IT-enabled services.
                                        <p>Agree to terms to proceed<span style={{ color: "red" }}>*</span></p>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default OccupationDetails;