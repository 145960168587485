import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    lineHeight: '16px',
    color: ' #505F79',
    fontWeight: 500,
    marginBottom: 6,
  },
  asterisk: {
    color: theme.palette.KenColors.ClientRed3,
  },
}));
export default function KenTextLabel(props) {
  const classes = useStyles();
  const { label, required, optionalLabel, helperText } = props;

  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography className={`${classes.label} ${props.labelClassName ? props.labelClassName : ''}`}>
          {label}
          {required ? <span className={classes.asterisk}>*</span> : ''}
          {helperText && (
            <span>
              <Tooltip title={helperText}>
                <HelpOutlineIcon
                  sx={{
                    height: '12px',
                    width: '12px',
                    marginLeft: '5px',
                    marginBottom: '-2px',
                  }}
                />
              </Tooltip>
            </span>
          )}
        </Typography>
      </Grid>
      {optionalLabel && (
        <Grid item>
          <Typography className={classes.optional} variant="subtitle1">
            {!required && optionalLabel ? '' : ' '}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
