export const registerVerificationForm = [
  {
    stepLabel: 'Personal Details',
    bannerTitle: {
      head: 'Setup your profile',
      subHead: 'Just a few more details to get you started!',
    },
    sections: [
      {
        sectionLabel: '',
        ui: {
          divider: true,
        },
        fields: [
          {
            type: 'upload',
            name: 'profile',
            required: true,
            validation: {
              required: {
                value: true,
                message: 'Field is required',
              },
            },
          },
          {
            type: 'birth',
            name: 'dob',
            label: 'Date of Birth',
            required: true,
          },
          {
            type: 'radio',
            name: 'gender',
            fields: [
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
              { value: 'others', label: 'Others' },
            ],
            required: true,
          },
        ],
      },
    ],
  },
  {
    stepLabel: 'Address',
    bannerTitle: {
      head: 'Setup your Address',
      subHead: 'Just a few more details to get you started!',
    },
    sections: [
      {
        sectionLabel: 'Address',
        fields: [
          {
            type: 'address',
          },
        ],
      },
    ],
  },
  {
    stepLabel: 'Occupation',
    bannerTitle: {
      head: 'Occupation details',
      subHead: 'Help us get to know you, so that we can customize your learning',
    },
    sections: [
      {
        sectionLabel: '',
        ui: {
          divider: false,
        },
        fields: [
          {
            type: 'select',
            name: 'currentOccupation',
            label: 'Current Occupation',
            required: true,
            options: [
              { label: 'Working Professional', value: 'Working Professional' },
              { label: 'Unemployed', value: 'Unemployed' },
              { label: 'Student', value: 'Student' },
            ],
          },
          {
            type: 'select',
            name: 'currentEmployment',
            label: 'Current Employment Details',
            rule: { scope: 'currentOccupation', value: 'Working Professional' },
            required: true,
            options: [
              { label: 'IT Industry with IT role', value: 'IT Industry with IT role' },
              { label: 'Non IT Industry with IT role', value: 'Non IT Industry with IT role' },
              { label: 'IT Industry with non IT role', value: 'IT Industry with non IT role' },
              { label: 'Non-IT Industry with Non IT Role', value: 'Non-IT Industry with Non IT Role' },
              { label: 'Employee of state/central govt or PSU or autonomous societies', value: 'Employee of state/central govt or PSU or autonomous societies' },
              { label: 'Trainers of resource centers or partner agencies or collaborated academia', value: 'Trainers of resource centers or partner agencies or collaborated academia' },
            ],
          },
          {
            type: 'select',
            name: 'mostAppropriate',
            label: 'Select the option most appropriate for you',
            required: true,
            rule: { scope: 'currentOccupation', value: 'Unemployed' },
            options: [
              { label: 'Unemployed with job offer', value: 'Unemployed with job offer' },
              { label: 'Unemployed without job offer', value: 'Unemployed without job offer' },
            ],
          },
          {
            type: 'select',
            name: 'PastEmploymentDetails',
            label: 'Past Employment Details',
            required: true,
            rule: { scope: 'currentOccupation', value: 'Unemployed' },
            options: [
              { label: 'Never been employed', value: 'Never been employed' },
              { label: 'IT Industry with IT role', value: 'IT Industry with IT role' },
              { label: 'Non-IT industry with IT role T Industry with non IT role', value: 'Non-IT industry with IT role T Industry with non IT role' },
              { label: 'Non-IT industry with non IT role', value: 'Non-IT industry with non IT role' },
              { label: 'Employee of State/Central Govt. or PSUs or autonomous societies', value: 'Employee of State/Central Govt. or PSUs or autonomous societies' },
              { label: 'Trainers of Resource Centers or Partner Agencies or Collaborated Academia', value: 'Trainers of Resource Centers or Partner Agencies or Collaborated Academia' },
            ],
          },
          {
            type: 'select',
            name: 'studentProfessional',
            label: 'Education Details',
            required: true,
            rule: { scope: 'currentOccupation', value: 'Student' },
            options: [
              { label: 'Doctorate', value: 'Doctorate' },
              { label: 'Postgraduate', value: 'Postgraduate' },
              { label: 'Undergraduate', value: 'Undergraduate' },
              { label: 'Others', value: 'Others' },
            ],
          },
        ],
      },
    ],
  },
  {
    stepLabel: 'Verification',
    bannerTitle: {
      head: 'Add your phone number',
      subHead: 'Stay connected everywhere! No spamming, we promise.',
    },
    sections: [
      {
        sectionLabel: '',
        ui: {
          divider: false,
        },
        fields: [
          {
            type: 'verifyOTP',
          },
        ],
      },
    ],
  },
];
