import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    background: theme.palette.KenColors.kenWhite,
    padding: '25px 64px',
    textAlign: 'start',
    fontFamily: 'GeneralSans',
    [theme.breakpoints.down('sm')]: {
      padding: '25px 36px',
    },
  },
  nav: {
    background: theme.palette.KenColors.kenWhite,
    height: '90vh',
    minHeight: '90vh',
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'relative',
  },
  actions: {
    background: theme.palette.KenColors.kenWhite,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 48px',
    height: '82px',
    // width: '165px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 10px',
    },
  },
  actionLogo: {
    height: '60px',
    width: '118px',
    // objectFit: 'cover',
  },
  profilImage: {
    height: '144px',
    width: '144px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginBottom: '24px',
  },
  listItemButton: {
    borderRadius: '10px 0px 0px 10px !important',
    marginLeft: '10px !important',
  },
  listItemButtonActive: {
    background: `${theme.palette.KenColors.ClientPrimary} !important`,
  },
  listItemIcon: {
    color: theme.palette.KenColors.grey,
  },
  listItemIconActive: {
    color: theme.palette.KenColors.kenWhite,
  },
  listItemText: {
    color: `${theme.palette.KenColors.ClientBlack2}`,
  },
  listItemTextActive: {
    color: `${theme.palette.KenColors.kenWhite}`,
  },
  listText:{
    fontSize: '20px !important',
    fontWeight: '600 !important',
    textDecoration: 'none',
    textTransform: 'capitalize',
    margin: '0px !important',
    padding: '0px !important',
  }
}));
