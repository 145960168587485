import React, { useState, useEffect } from 'react';
import { Box, Button, Card, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Image from '../../../Assets/assetHelper';
import { Carousel } from 'react-responsive-carousel';
import carousel1 from '../../../Assets/carousel.png';
import carousel2 from '../../../Assets/carousel1.png';
import carousel3 from '../../../Assets/carousel2.png';
import routes from '../../../Utils/routes';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SchoolIcon from '@mui/icons-material/School';
import HistoryIcon from '@mui/icons-material/History';
import EastIcon from '@mui/icons-material/East';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getQuizInstruction, quizStartAttempt, getUserAttempts } from '../../../Utils/apiService';
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';
import KenDialog from '../../../GlobalComponents/KenDialog';
import KenButton from '../../../GlobalComponents/KenButton';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const AssesmentTest: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const quizid = queryParams.get('quiz_id');
  const theme = useTheme();
  const UserData = localStorage.getItem('userData');
  const userData = UserData ? JSON.parse(UserData) : null;
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const [isCarousel, setIsCarousel] = useState(true);
  const navigate = useNavigate();
  const [NewQuizId, setNewQuizId] = useState("")
  const [AttemptCout, setAttemptCout] = useState("1")
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const Continue = () => {
    setDialogOpen(false);
    setIsCarousel(!isCarousel);
    setTimerStarted(true);
  }
  const [Data, setData] = useState<any[]>([])
  const [Newtest, setNewtest] = useState<boolean>(false);
  const toggleCarousel = () => {
    const datas = {
      "method": "post",
      "quizid": quizid,
      "status": "all"
    }
    getUserAttempts(datas).then((res) => {
      setData(res?.data)
      if (Array.isArray(res?.data) && res?.data.length > 0) {
        let maxIdObj = res?.data[0];
        for (let i = 1; i < res?.data.length; i++) {
          if (res?.data[i].id > maxIdObj.id) {
            maxIdObj = res?.data[i];
          }
        }
        setAttemptCout(maxIdObj?.attempt)
        if (maxIdObj.state === "inprogress") {
          setNewQuizId(maxIdObj?.id)
          setAttemptCout(maxIdObj?.attempt)
          setDialogOpen(true);
          setNewtest(false)
        }
        else {
          // const data = {
          //   "method": "post",
          //   "quizid": quizid,
          //   "preflightdata": [],
          //   "forcenew": true
          // }
          // quizStartAttempt(data).then((res) => {
          //   setDialogOpen(true);
          //   setNewQuizId(res?.data?.id)
          //   setAttemptCout(res?.data?.attempt)
          //   setTest(false)
          // })
          setNewtest(true)
          setDialogOpen(true);
        }
      }
      // else {
      //   const data = {
      //     "method": "post",
      //     "quizid": quizid,
      //     "preflightdata": [],
      //     "forcenew": true
      //   }
      //   quizStartAttempt(data).then((res) => {
      //     setDialogOpen(true);
      //     setNewQuizId(res?.data?.id)
      //     setAttemptCout("1")
      //     setTest(false) 
      //   })
      // }
      else {
        setNewtest(true)
        setDialogOpen(true);
      }
    })

  };
  const startNewTest = () => {
    const data = {
      "method": "post",
      "quizid": quizid,
      "preflightdata": [],
      "forcenew": true
    }
    quizStartAttempt(data).then((res) => {
      setDialogOpen(true);
      setNewQuizId(res?.data?.id)
      setAttemptCout(res?.data?.attempt)
      navigate(`${routes.TestAssesments}?quizId=${res?.data?.id}`)
    })
  }
  const [QuizData, setQuizData] = useState<any>();
  useEffect(() => {
    const data = {
      "method": "post",
      "quizid": quizid ? quizid : 5567
    }
    getQuizInstruction(data)
      .then(res => {
        setQuizData(res?.data)
      })
  }, [])
  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${secondsLeft}`}</Typography>
        </Box>
      </Box>
    );
  }
  const [secondsLeft, setSecondsLeft] = useState(50);
  const [timerStarted, setTimerStarted] = useState(false);
  const [initialTime, setInitialTime] = useState(50);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (timerStarted) {
      timer = setInterval(() => {
        if (secondsLeft > 0) {
          setSecondsLeft(secondsLeft - 1);
        } else {
          clearInterval(timer);
          if (Newtest === false) {
            navigate(`${routes.TestAssesments}?quizId=${NewQuizId}`);
          }
          else {
            startNewTest()
          }
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [secondsLeft, navigate, NewQuizId, timerStarted]);

  const isXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <KenDialog open={isDialogOpen} minWidth={'100%'}>
        <Box minWidth={isXs ? '300px' : '548px'}>
          <Grid container style={{ display: 'flex', padding: '10px', borderRadius: '12px', alignItems: 'center' }}>
            <Grid lg={11}>Please note before you take the quiz </Grid>
            <Grid lg={1}><CloseIcon onClick={handleCloseDialog} style={{ height: 30, width: 30, color: "#585A64" }} /></Grid>
          </Grid>
          <Divider />
          <Grid container style={{ display: 'flex', padding: '10px', borderRadius: '12px', alignItems: 'center' }}>
            <Grid lg={6} padding={1}>
              <Grid container style={{ display: 'flex', padding: '10px', borderRadius: '12px', alignItems: 'center', backgroundColor: "#F6F8FB" }}>
                <Grid><SchoolIcon style={{ height: 30, width: 30, color: "#585A64", padding: 10 }} /></Grid>
                <Grid marginTop={2}>
                  <Grid>
                    Receive grade
                  </Grid>
                  <Grid>
                    <b>To pass <span style={{ color: "#388E3C" }}>75% or higher</span></b>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid lg={6} padding={1}>
              <Grid container style={{ display: 'flex', padding: '10px', borderRadius: '12px', alignItems: 'center', backgroundColor: "#F6F8FB" }}>
                <Grid><HistoryIcon style={{ height: 30, width: 30, color: "#585A64", padding: 10 }} /></Grid>
                <Grid marginTop={2}>
                  <Grid>
                    Attempt number
                  </Grid>
                  <Grid>
                    <b>{AttemptCout}st attempt</b>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {Data?.length === 0 ? null :
            <Grid padding={2}>
              <Grid padding={2} border={"1px solid #e0e0e0"} borderRadius={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>Attempt</TableCell>
                        {/* <TableCell align='center'>Percentage</TableCell> */}
                        <TableCell align='center'>Percentage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Data.map((row: any) => (
                        <TableRow
                          key={row.data}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row" align='center'>
                            {row.attempt}
                          </TableCell>
                          <TableCell align='center'>
                            {row.sumgrades === null ? "Quiz Inprogress" : Math.round((row.sumgrades / (QuizData?.totalmarks ?? 1)) * 100)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>}
          <div style={{ display: 'flex', marginTop: '30px' }}>
            <KenButton variant="primary" style={{ width: isXs ? "70%" : "100%", background: 'linear-gradient(90deg, #581212 -92.71%, #C33531 100%)', borderRadius: '8px' }} onClick={Continue}>
              Continue<EastIcon style={{ height: 20, width: 30, color: "white", padding: 5 }} />
            </KenButton>
          </div>
        </Box>
      </KenDialog>
      <Card style={{ width: '80%', minHeight: '80vh', display: 'flex', flexDirection: 'column', borderRadius: '24px' }}>
        <Grid container style={{ height: '75vh' }}>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <div style={{ margin: '72px' }}>
              <img onClick={() => {
                navigate('/home');
              }} alt="logo" className={classes.actionLogo} src={Image.Images.DefaultOrganizationLogo} />
              <div style={{ marginTop: '45px', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="extraverylargebold">Hi {userData?.name}</Typography>
                <Typography variant="mediumthin" style={{ marginTop: '15px' }}>
                  Welcome to {QuizData?.quizname} Assessment
                </Typography>
              </div>
              <div style={{ marginTop: '45px', display: 'flex', marginRight: '20px' }}>
                <Grid container spacing={2} style={{ alignItems: 'center', marginTop: '20px' }}>
                  <Grid item md={12} lg={12} xl={12} xs={12}>
                    <Grid style={{display:"flex"}}>
                      <Grid item md={4} lg={4} xl={3} xs={10} marginRight={3}>
                        <div style={{ backgroundColor: '#F6F8FB', padding: '10px', borderRadius: '12px', width: "110px" }}>
                          <Typography variant="smallthin">Question count:</Typography>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <Typography variant="mediumbold" style={{ marginLeft: '5px' }}>
                              {QuizData?.totalmarks} questions
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4} lg={4} xl={3} xs={10} marginRight={3}>
                        <div style={{ backgroundColor: '#F6F8FB', padding: '10px', borderRadius: '12px', width: "110px" }}>
                          <Typography variant="smallthin">Section count:</Typography>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <Typography variant="mediumbold" style={{ marginLeft: '5px' }}>
                              {QuizData?.sections?.length} sections
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4} lg={4} xl={3} xs={10}>
                        <div style={{ backgroundColor: '#F6F8FB', padding: '10px', borderRadius: '12px', width: "110px" }}>
                          <Typography variant="smallthin">Test duration:</Typography>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <Typography variant="mediumbold" style={{ marginLeft: '5px' }}>
                              {QuizData?.timelimit / 60} minutes
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          <Divider sx={{ margin: '30px' }} orientation="vertical" flexItem />
          <Grid item md={5} lg={5} xl={5} style={{ overflowY: isSmallerScreen ? 'scroll' : isExtraLargeScreen ? 'auto' : 'hidden' }}>
            {isCarousel ? (
              <div style={{ height: '50vh', paddingTop: "11%" }}>
                <Carousel showStatus={false} showIndicators={true} className="custom-carousel">
                  {[
                    <div key="carousel-slide-1">
                      <div style={{ marginTop: '10px' }}>
                        {' '}
                        <img src={carousel1} style={{ height: '163px', width: '211px' }} alt="Carousel Image 1" />
                        <div style={{ marginTop: '65px', display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="largemedium">Don’t refresh</Typography>
                          <Typography variant="mediumthin" style={{ marginTop: '20px' }}>
                            Don’t refresh the webpage during the assessment. This will lead to immediate submission of your responses.
                          </Typography>
                        </div>
                      </div>
                    </div>,
                    <div key="carousel-slide-2">
                      <div style={{ marginTop: '10px', height: '60vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={carousel2} style={{ height: '163px', width: '211px' }} alt="Carousel Image 1" />
                        <div style={{ marginTop: '65px', display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="largemedium">Internet connectivity</Typography>
                          <Typography variant="mediumthin" style={{ marginTop: '20px' }}>
                            Ensure that you have a stable internet connection with a minimum speed of 512 kbps.
                          </Typography>
                        </div>
                      </div>
                    </div>,
                    <div key="carousel-slide-3">
                      <div style={{ marginTop: '10px' }}>
                        <img src={carousel3} style={{ height: '181px', width: '200px' }} alt="Carousel Image 1" />
                        <div style={{ marginTop: '65px', display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="largemedium">Auto-Save</Typography>
                          <Typography variant="mediumthin" style={{ marginTop: '20px' }}>
                            All your responses are saved automatically. In case of disconnection or shutdowns, you will still be able to resume easily.
                          </Typography>
                        </div>
                      </div>
                    </div>,
                  ]}
                </Carousel>
              </div>
            ) : (
              <div style={{ margin: '80px 20px', height: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="largemedium">Ready to start?</Typography>
                    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#E6E6E8', borderRadius: '8px', padding: '8px' }}>
                      <div style={{ width: 25, height: 20, position: 'relative' }}>
                        <CircularProgressWithLabel style={{ width: 25, height: 25 }} value={(secondsLeft / initialTime) * 100} />
                      </div>
                      <Typography style={{ marginLeft: '8px' }}> secs</Typography>
                    </div>
                  </div>
                  <Typography variant="mediumthin" style={{ marginTop: '25px', marginBottom: "20px" }}>
                    Select the section you would like to attempt first and then click on “Start test” button.
                  </Typography>
                  <div style={{ marginTop: '20px', margin: '0px', border: '1px solid #E6E6E8', borderRadius: '12px' }}>
                    <table style={{ borderCollapse: 'collapse', margin: '20px', padding: '10px', width: isSmallerScreen ? '90%' : '27vw' }}>
                      <thead>
                        <tr style={{ textAlign: 'center', fontSize: '16px', color: '#581212', fontWeight: 500, borderBottom: '1px solid #E6E6E8' }}>
                          <th style={{ fontWeight: 500, paddingBottom: '20px', width: "150px" }}>Section name</th>
                          <th style={{ fontWeight: 500, paddingBottom: '20px', width: "150px" }}>No. of questions</th>
                          <th style={{ fontWeight: 500, paddingBottom: '20px', width: "150px" }}>Marks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {QuizData?.sections.map((section: any, index: any) => (
                          <tr key={index} style={{ textAlign: 'center' }}>
                            <td style={{ padding: '10px' }}>
                              {/* Adding radio button with section name */}
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* <input
                                  type="radio"
                                  id={`section-${index}`}
                                  name="selectedSection"
                                  value={section.name}
                                  onChange={() => handleRadioChange(section.name)} // handleRadioChange is your function to handle radio selection
                                /> */}
                                <label htmlFor={`section-${index}`} style={{ marginLeft: '10px' }}>
                                  {section?.heading}
                                </label>
                              </div>
                            </td>
                            <td style={{ padding: '10px' }}>
                              <div style={{ margin: '10px' }}>{section?.totalquestion} question</div>

                            </td>
                            <td style={{ padding: '10px' }}>
                              <div style={{ margin: '10px' }}>{section?.totalmarks} Marks</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0, marginTop: '10px', textAlign: 'left' }}>
                      <li style={{ marginRight: '8px' }}>•</li>
                      <li>
                        <Typography variant="medium">Untimed:</Typography>
                      </li>
                      <li>
                        <Typography variant="mediumthin" style={{ marginLeft: '5px' }}>
                          These sections are without any specific time limit. You can answer these sections within the total assessment time limit.
                        </Typography>
                      </li>
                    </ul>
                  </div>
                  <div style={{ display: 'flex', marginTop: '40px' }}>
                    <Typography variant="medium">Total Time of Untimed Sections =</Typography>
                    <Typography variant="mediumthin">Total time of test - Total time of Timed sections</Typography>
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <div style={{ bottom: '0', background: '#FFFFFF', boxShadow: '0px -4px 44px 0px #BCBCBC1A', display: 'flex', justifyContent: 'end', padding: '18px 24px', position: 'relative', zIndex: '0' }}>
          {isCarousel ? (
            <Button onClick={toggleCarousel} type="submit" className="buttonmedium" style={{ background: 'linear-gradient(90deg, #581212 -92.71%, #C33531 100%) !important' }} variant="contained">
              Proceed
            </Button>
          ) : (
            <>
              {Newtest === false ?
                <Button
                  className="buttonmedium"
                  style={{
                    background: 'linear-gradient(90deg, #581212 -92.71%, #C33531 100%)',
                    color: 'white',
                  }}
                  variant="contained"
                  onClick={() => {
                    navigate(`${routes.TestAssesments}?quizId=${NewQuizId}`)
                  }}
                >
                  Resume Test
                </Button> :
                <Button
                  className="buttonmedium"
                  style={{
                    background: 'linear-gradient(90deg, #581212 -92.71%, #C33531 100%)',
                    color: 'white',
                  }}
                  variant="contained"
                  onClick={startNewTest}
                >
                  Start test
                </Button>}
            </>
          )}
        </div>
      </Card>
    </Box>
  );
};

export default AssesmentTest;
