const Images = {
  Raise: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/Group%2048099673.png",
  Education: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/education.png",
  Medal: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/medal-star.png",
  Credit: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/two_credit_cart.png",
  DefaultProfileImage: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/profile.png",
  DefaultOrganizationLogo: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Images/logo.png",
  AuthLayoutBg: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/LoginBackground.png",
  NoDataTheme: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/noEvents.png",
  PostEventBanner: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/Plant.png",
  PostJobBanner: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/PostJob.png",
  EventCardCoverImage: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/noimage.jpg",
  OnBoardBg: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/backgroundonboard.png",
  Complain: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/Objects.png",
  MentorBanner: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/mentorbanner.png",
  samplePrfileImage: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/Ellipse%2038.png",
  sampleCertificate: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Images/sampleCertificate.jpg",
};
const Svg = {
  barChart: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/barChart.png",
  analytics: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/analytics.png",
  notes: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/notes.png",
  noUpcomingEvents: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/noUpcomingEvents.png",
  pdf: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/pdf.png",
  assessment: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/assessment.png",
  video: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/video.png",
};
const Icons = {
  registered:"https://flamestorageken42.blob.core.windows.net/flame-storage-ken42/tick icon.svg",
  Error: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/error.png",
  Google: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/google.png",
  linkedIn: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/linkedinlogo.png",
  facebook: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Svg/facebook.png",
  zoomin: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Icons/ZoomIn.png",
  zoomout: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Icons/ZoomOut.png",
  edit: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Icons/Edit.png",
  print: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Icons/Print.png",
  download: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Icons/download.png",
  info: "https://inazstgpfs3001.blob.core.windows.net/assets/NASCOM/LMS/Icons/info.png",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { Images, Icons, Svg };
