import React, { useContext, useState } from 'react';
import { Grid, IconButton, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, useMediaQuery, SwipeableDrawer, Drawer, Button, Popover } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { AuthContext, useAuthContext } from '../../Helpers/Contexts/authContext';
import { useNavigate } from 'react-router-dom';
import SideNavTabs from './Tabs';
import { useStyles } from './styles';
import { ReactChildren } from '../../interfaces/Common';
import AppBreadCrumbs from '../BreadCrumbs';
import Image from '../../Assets/assetHelper';
import KenColors from '../../Helpers/Themes/kenColors';
import { AuthState } from '../../interfaces/Contexts';
import { style } from '@mui/system';
import { Link } from 'react-router-dom';

const AppLayout: React.FunctionComponent<ReactChildren> = ({ children }) => {
  const classes = useStyles();
  const UserData = localStorage.getItem('userData');
  const userDatas = UserData ? JSON.parse(UserData) : null;
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useAuthContext();
  const { user: userData } = state;
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const marginLeftValue = isMobileScreen ? (isDrawerOpen ? '10px' : '30px') : isDrawerOpen ? '260px' : '50px';
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleListClick = (path: string, index: number) => {
    navigate(path);
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12} md={2} lg={2} xl={1.5} sm={1}>
        {isMobileScreen ? (
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={toggleDrawer}
            variant="permanent"
            PaperProps={{
              style: {
                width: isDrawerOpen ? '240px' : '55px',
              },
            }}
          >
            <DrawerContent />
          </Drawer>
        ) : (
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={toggleDrawer}
            variant="permanent"
            PaperProps={{
              style: {
                width: isDrawerOpen ? '240px' : '65px',
              },
            }}
          >
            <DrawerContent />
          </Drawer>
        )}
      </Grid>
      <Grid item xs={12} md={isDrawerOpen ? 10 : 11.5} lg={isDrawerOpen ? 10 : 11.5} xl={isDrawerOpen ? 10.5 : 11.5} sm={isDrawerOpen ? 10 : 11.5} style={{ marginLeft: marginLeftValue }}>
        <Grid container spacing={3} lg={12}>
          <Grid item container spacing={2} xs={12} lg={12}>
            <Grid item xs={12} md={8}>
              <Paper className={classes.header} variant="scrollContained">
                <AppBreadCrumbs />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper variant="scrollContained" className={classes.actions}>
                <IconButton>
                  <NotificationsIcon fontSize="large" />
                </IconButton>
                  <button style={{ background: "none", border: "none" }}
                    aria-describedby={id}
                    onClick={handleClick}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'none'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'none'}
                  >
                    {userDatas.profile_picture ? <img style={{ width: "35px", borderRadius: "50%" }} src={userDatas.profile_picture} alt="profile" /> : <AccountCircleIcon fontSize="large" />}
                  </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 2 }}><a href='/profile'>View Profile</a></Typography>
                </Popover>
                <div style={{ width: '1px', height: '60px', background: KenColors.ClientBlue8 }} />
                <img alt="logo" className={classes.actionLogo} src={Image.Images.DefaultOrganizationLogo} />
              </Paper>
            </Grid>
          </Grid>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );

  function DrawerContent() {
    return (
      <Paper variant="scrollContained" className={classes.nav}>
        <div>
          {isDrawerOpen && (
            <div style={{ margin: '36px 25px', textAlign: 'center' }}>
              <img className={classes.profilImage} src={userDatas?.profile_picture ? userDatas.profile_picture : Image.Images.DefaultProfileImage} alt="profile" />
              <Typography component={'h6'} variant="largebold" color={KenColors.ClientBlack2} className={classes.listText}>
                {userDatas?.name}
              </Typography>
              {/* {userData?.graduationYear !== 'null' && (
                <Typography component={'h6'} variant="mediumthin" color={KenColors.ClientBlack2}>
                  Students
                </Typography>
              )} */}
            </div>
          )}
          <IconButton style={{ display: 'flex', float: 'right', marginTop: '20px' }} onClick={toggleDrawer}>
            {isDrawerOpen ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
          </IconButton>
          <div style={{ marginTop: '90px' }}>
            <SideNavTabs handleListClick={handleListClick} />
          </div>
        </div>

        <div onClick={() => {
          navigate('/');
        }}>
          <ListItemButton
            className={classes.listItemButton}
            onClick={() => {
              dispatch({ type: 'LOGOUT' });
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px' }}>
              <LogoutIcon fontSize="medium" className={classes.listItemIcon} />
            </ListItemIcon>
            <ListItemText

            >
              <Typography variant="mediumthin">Logout</Typography>
            </ListItemText>
          </ListItemButton>
        </div>
      </Paper>
    );
  }
};

export default AppLayout;
