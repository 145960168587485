import React, { useState, useEffect } from 'react';
import { Button, Card, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTimer } from 'react-timer-hook';
import Image from '../../../Assets/assetHelper';
import clock from '../../../Assets/clock.png';
import { useLocation } from 'react-router-dom';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useStyles } from './styles';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import TestSummary from './summary';
import { getAttemptData, answerSubmission } from '../../../Utils/apiService';
import KenLoader from '../../../GlobalComponents/KenLoader';
import CircleIcon from '@mui/icons-material/Circle';
const TestAssesments: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sections, setTransformedData] = useState<any>([]);
  const [SummaryData, setSummaryData] = useState<any>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const quizId = queryParams.get('quizId');
  const classes = useStyles();
  const [Summary, setSummary] = useState<boolean>(false)
  const UserData = localStorage.getItem('userData');
  const userData = UserData ? JSON.parse(UserData) : null;
  const [selectedButtonIndex, setSelectedButtonIndex] = useState<number>(1);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [answers, setAnswers] = useState<{ [key: string]: string[] }>({});
  const [visitedQuestions, setVisitedQuestions] = useState<string[]>([]);
  const [revisitLater, setRevisitLater] = useState<string[]>([]);
  const [AnsweredflaggedCount, setAnsweredflaggedCount] = useState<any>();
  const [answeredAndFlaggedCount, setansweredAndFlaggedCount] = useState<any>();
  const [unansweredAndunFlaggedCount, setunansweredAndunFlaggedCount] = useState<any>();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const [startTime, setstartTime] = useState<any>()
  const [updateFlag, setUpdateFlag] = useState(false);
  const [TimeDuration, setTimeDuration] = useState<number>(2400);
  useEffect(() => {
    setLoading(true);
    const data = {
      method: "post",
      attemptid: quizId,
      page: "all",
      preflightdata: []
    };
    getAttemptData(data).then((res) => {
      setstartTime(res?.data?.attempt?.timestart);
      setTimeDuration(parseInt(res?.data?.timelimit));
      const response = res?.data?.questions;
      if (response) {
        const transformedResponse = transformApiResponse(response);
        setTransformedData(transformedResponse);
        const answerSavedCount = response.filter((item: { status: string; }) => item.status === "Answer saved").length;
        const flaggedCount = response.filter((item: { flagged: boolean; }) => item.flagged === true).length;
        const answeredAndFlaggedCount = response.filter((item: { flagged: boolean; status: string; }) => item.flagged === true && item.status === "Answer saved").length;
        const unansweredAndunFlaggedCount = response.filter((item: { flagged: boolean; status: string; }) => item.flagged === false && item.status === "Not yet answered").length;
        setunansweredAndunFlaggedCount(unansweredAndunFlaggedCount)
        setansweredAndFlaggedCount(answeredAndFlaggedCount)
        setAnsweredflaggedCount({ answerSavedCount, flaggedCount });
        markQuestionAsVisited(1, 1);
        setLoading(false);
      }
    });
  }, [updateFlag]);
  const transformApiResponse = (response: any) => {
    setLoading(true);
    const result: any = [];
    if (response && response.length > 0) {
      response.forEach((item: any) => {
        let section = result.find((sec: { section: any; }) => sec.section === item.section);
        if (!section) {
          section = { section: item.section, questions: [] };
          result.push(section);
          setLoading(false);
        }
        if (item.attemptdata && item.attemptdata.length > 0) {
          item.attemptdata.forEach((question: any) => {
            section.questions.push({
              ...question,
              flagged: item.flagged,
              maxmark: item.maxmark,
              number: item.number,
              page: item.page,
              responsefileareas: item.responsefileareas,
              section: item.section,
              sequencecheck: item.sequencecheck,
              slot: item.slot,
              status: item.status,
              type: item.type
            });
          });
          setLoading(false);
        }
      });
    }
    return result;
  };
  const handleChange = (index: number) => {
    setLoading(true);
    const newAnswers = { ...answers };
    if (!newAnswers[selectedButtonIndex]) {
      newAnswers[selectedButtonIndex] = [];
    }
    newAnswers[selectedButtonIndex][currentQuestionIndex] = index.toString();
    setAnswers(newAnswers);
    setSelectedOption(index.toString());
    const currentQuestion = sections[currentSectionIndex].questions[currentQuestionIndex];
    Submission(currentQuestion, index);
    const questionId = `S${selectedButtonIndex}Q${currentQuestionIndex + 1}`;
    setRevisitLater((prev) => prev.filter((id) => id !== questionId));
    setTimeout(() => {
      const data = {
        method: "post",
        attemptid: quizId,
        page: "all",
        preflightdata: []
      };
      getAttemptData(data).then((res) => {
        setstartTime(res?.data?.attempt?.timestart);
        const response = res?.data?.questions;
        if (response) {
          const transformedResponse = transformApiResponse(response);
          setTransformedData(transformedResponse);
          const answerSavedCount = response.filter((item: { status: string; }) => item.status === "Answer saved").length;
          const flaggedCount = response.filter((item: { flagged: boolean; }) => item.flagged === true).length;
          const answeredAndFlaggedCount = response.filter((item: { flagged: boolean; status: string; }) => item.flagged === true && item.status === "Answer saved").length;
          const unansweredAndunFlaggedCount = response.filter((item: { flagged: boolean; status: string; }) => item.flagged === false && item.status === "Not yet answered").length;
          setunansweredAndunFlaggedCount(unansweredAndunFlaggedCount)
          setansweredAndFlaggedCount(answeredAndFlaggedCount)
          setAnsweredflaggedCount({ answerSavedCount, flaggedCount });
          setTimeout(() => {
            setLoading(false);
          }, 500)
        }
      });
    }, 500);
  };
  const handleClick = (index: number) => {
    setLoading(true);
    markQuestionAsVisited(currentSectionIndex + 1, currentQuestionIndex + 1);
    setSelectedButtonIndex(index);
    setCurrentSectionIndex(index - 1);
    setCurrentQuestionIndex(0);
    setSelectedOption(answers[index] ? answers[index][0] : '');
    setLoading(false);
  };
  const markQuestionAsVisited = (sectionIndex: number, questionIndex: number) => {
    const questionId = `S${sectionIndex}Q${questionIndex}`;
    setVisitedQuestions((prev) => {
      if (!prev.includes(questionId)) {
        return [...prev, questionId];
      }
      return prev;
    });
  };
  const handleRevisitLater = (section: any) => {
    const userResponse = section.userresponse;
    const questionOptions = section.questionoption;
    const matchedIndex = questionOptions.findIndex((option: string) => option.includes(userResponse));
    const data = {
      "method": "post",
      "attemptid": quizId,
      "data": [
        {
          "name": `q${section?.qubaid}:${section?.slot}_:sequencecheck`,
          "value": `${section?.sequencecheck}`
        },
        {
          "name": `q${section?.qubaid}:${section?.slot}_:flagged`,
          "value": section?.flagged ? 0 : 1
        },
        {
          "name": "slots",
          "value": `${section?.slot}`
        }
      ],
      "preflightdata": []
    };
    if (section?.status === "Answer saved") {
      data.data.push({
        "name": `q${section?.qubaid}:${section?.slot}_answer`,
        "value": matchedIndex
      });
    }
    answerSubmission(data).then((res) => {
      setUpdateFlag((prev) => !prev);
    });
    const questionId = `S${selectedButtonIndex}Q${currentQuestionIndex + 1}`;
    setRevisitLater((prev) => [...new Set([...prev, questionId])]);
    if (!isLastQuestion) {
      handleNextQuestion();
    }
  };
  const clearResponse = (section: any) => {
    setLoading(true);
    const data = {
      "method": "post",
      "attemptid": quizId,
      "data": [
        {
          "name": `q${section?.qubaid}:${section?.slot}_:sequencecheck`,
          "value": `${section?.sequencecheck}`
        },
        {
          "name": "slots",
          "value": `${section?.slot}`
        }
      ],
      "preflightdata": []
    };
    answerSubmission(data).then((res) => {
      setTimeout(() => {
        const data = {
          method: "post",
          attemptid: quizId,
          page: "all",
          preflightdata: []
        };
        setLoading(true);
        getAttemptData(data).then((res) => {
          setstartTime(res?.data?.attempt?.timestart);
          const response = res?.data?.questions;
          if (response) {
            const transformedResponse = transformApiResponse(response);
            setTransformedData(transformedResponse);
            const answerSavedCount = response.filter((item: { status: string; }) => item.status === "Answer saved").length;
            const flaggedCount = response.filter((item: { flagged: boolean; }) => item.flagged === true).length;
            const answeredAndFlaggedCount = response.filter((item: { flagged: boolean; status: string; }) => item.flagged === true && item.status === "Answer saved").length;
            const unansweredAndunFlaggedCount = response.filter((item: { flagged: boolean; status: string; }) => item.flagged === false && item.status === "Not yet answered").length;
            setunansweredAndunFlaggedCount(unansweredAndunFlaggedCount)
            setansweredAndFlaggedCount(answeredAndFlaggedCount)
            setAnsweredflaggedCount({ answerSavedCount, flaggedCount });
            setTimeout(() => {
              setLoading(false);
            }, 500)
          }
        });
      }, 500);
    });
  };
  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      markQuestionAsVisited(selectedButtonIndex, nextIndex);
      if (nextIndex < sections[currentSectionIndex].questions.length) {
        setSelectedOption(answers[selectedButtonIndex] ? answers[selectedButtonIndex][nextIndex] : '');
        return nextIndex;
      } else {
        handleClick(selectedButtonIndex + 1);
        return 0;
      }
    });
  };
  const submit = () => {
    setSummary(true)
    SectionSummary()
  }

  const goBackToTest = () => {
    setSummary(false);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => {
      const nextIndex = prevIndex - 1;
      if (nextIndex >= 0) {
        setSelectedOption(answers[selectedButtonIndex] ? answers[selectedButtonIndex][nextIndex] : '');
        return nextIndex;

      } else {
        handleClick(selectedButtonIndex - 1);
        return sections[selectedButtonIndex - 2].questions.length - 1;
      }
    });
  };
  
  const startTimeInSeconds = startTime;
  const durationInSeconds = TimeDuration;
  const expiryTimeInSeconds = startTimeInSeconds + durationInSeconds;
  const expiryTime = new Date(expiryTimeInSeconds * 1000);
  const { seconds, minutes, hours, restart } = useTimer({
    autoStart: true,
    expiryTimestamp: expiryTime,
    onExpire: () => {
      setSummary(true)
    },
  });
  const remainingTimeInSeconds = seconds + (minutes * 60) + (hours * 3600);
  const remainingHours = Math.floor(remainingTimeInSeconds / 3600);
  const remainingMinutes = Math.floor(remainingTimeInSeconds / 60);
const remainingSeconds = remainingTimeInSeconds % 60;
  
  useEffect(() => {
    restart(expiryTime);
  }, [startTimeInSeconds]);
  const formatTimeUnit = (unit: number) => unit.toString().padStart(2, '0');
  const totalQuestions = sections.reduce((acc: any, section: { questions: string | any[]; }) => acc + section.questions.length, 0);
  const isFirstQuestionInSection = currentQuestionIndex === 0;
  const isFirstSection = currentSectionIndex === 0;
  const isFirstQuestion = isFirstSection && isFirstQuestionInSection;
  const isLastQuestionInSection = currentQuestionIndex === sections[currentSectionIndex]?.questions.length - 1;
  const isLastSection = currentSectionIndex === sections.length - 1;
  const isLastQuestion = isLastSection && isLastQuestionInSection;
  const cartdatas = {
    Unattempted: unansweredAndunFlaggedCount,
    revisitLater: AnsweredflaggedCount?.flaggedCount - answeredAndFlaggedCount,
    Attempted: totalQuestions >= AnsweredflaggedCount?.answerSavedCount ? AnsweredflaggedCount?.answerSavedCount - answeredAndFlaggedCount : totalQuestions - answeredAndFlaggedCount,
    answeredAndFlagged: answeredAndFlaggedCount
  }
  const SectionSummary = () => {
    const summary = sections.map((section: any) => {
      const totalQuestions = section.questions.length;
      const answeredQuestions = section.questions.filter((q: any) => q.status === "Answer saved").length;
      const flaggedQuestions = section.questions.filter((q: any) => q.flagged).length;
      const answeredAndFlagged = section.questions.filter((q: any) => q.status === "Answer saved" && q.flagged).length;
      const unansweredAndUnflagged = section.questions.filter((q: any) => q.status === "Not yet answered" && !q.flagged).length;
      return {
        section: section.section,
        totalQuestions,
        answeredQuestions,
        flaggedQuestions,
        answeredAndFlagged,
        unansweredAndUnflagged
      };
    });
    setSummaryData(summary)
    return summary;
  };
  const notAnsweredCount = sections.reduce((acc: number, section: any, sectionIndex: number) => {
    const count = section.questions.reduce((sectionAcc: number, _: any, questionIndex: number) => {
      const isAnswered = answers[sectionIndex + 1] && answers[sectionIndex + 1][questionIndex];
      const questionId = `S${sectionIndex + 1}Q${questionIndex + 1}`;
      const isRevisitLater = revisitLater.includes(questionId);
      const isVisited = visitedQuestions.includes(questionId);
      if (isVisited && !isAnswered && !isRevisitLater) {
        sectionAcc++;
      }
      return sectionAcc;
    }, 0);
    return acc + count;
  }, 0);
  const userResponse = sections[currentSectionIndex]?.questions[currentQuestionIndex]?.userresponse;
  let defaultSelectedIndex = "1";
  if (userResponse) {
    defaultSelectedIndex = sections[currentSectionIndex].questions[currentQuestionIndex].questionoption.findIndex((option: any) => option === userResponse).toString();
  }
  const Submission = (questionData: any, selectedOption: any) => {
    const submissionData = {
      question: questionData,
      selectedOption: selectedOption
    };
    const data = {
      "method": "post",
      "attemptid": quizId,
      "data": [
        {
          "name": `q${submissionData?.question?.qubaid}:${submissionData?.question?.slot}_:sequencecheck`,
          "value": `${submissionData?.question?.sequencecheck}`
        },
        {
          "name": `q${submissionData?.question?.qubaid}:${submissionData?.question?.slot}_answer`,
          "value": `${parseInt(submissionData?.selectedOption)}`
        },
        {
          "name": "slots",
          "value": `${submissionData?.question?.slot}`
        },
      ],
      "preflightdata": []
    }
    answerSubmission(data).then((res) => {
      // console.log(res, "ressssss");
    })
  };
  const isXs = useMediaQuery(theme.breakpoints.down('md'));

  // console.log(remainingTimeInSeconds, remainingMinutes, remainingSeconds, 'remainingTimeInSeconds');
  
  
  return (
    <div style={{ margin: '20px' }}>
      {loading && <KenLoader />}
      {Summary === false ?
        <>
          <Card style={{ width: '98%', display: 'flex', flexDirection: 'column', borderRadius: '24px', marginTop: '30px' }}>
            <Grid container padding={1}>
              <Grid item md={8.5} xs={4}>
                <img alt="logo" className={classes.actionLogo} src={Image.Images.DefaultOrganizationLogo} style={{marginTop: isXs? '26px': '0px'}} />
              </Grid>
              <Grid item md={1.5} xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #ccc', marginTop: 30, textAlign: "right", paddingRight: 10 }}>
                  <Typography variant="largemedium">{userData?.name}</Typography>
                </div>
              </Grid>
              <Grid item md={2} xs={4}>
                <div style={{ display: 'flex', marginTop: '30px', marginLeft: '10px', color: '#193389' }}>
                  <CloudDoneOutlinedIcon />
                  <Typography variant="medium" style={{ marginLeft: '10px' }}>
                    saved: 0 seconds ago
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
          <div style={{ display: 'flex', flexDirection: isXs ? 'column': 'row' }}>
            <Card style={{ width: isXs? '90%':'78%', display: 'flex', flexDirection: 'column', borderRadius: '24px', padding: '20px', marginTop: '20px' }}>
              <Grid container spacing={2} alignItems="center" style={{ marginTop: '2px' }}>
                <Grid item xl={1} lg={1.5} md={1.5}>
                  <div style={{ marginLeft: '16px' }}>
                    <Typography variant="largemedium">Sections</Typography>
                  </div>
                </Grid>
                <Grid item xl={8.5} lg={7.5} md={6}>
                  <div style={{ display: 'flex',marginLeft:10 }}>
                    {sections.map((section: any, index: any) => (
                      <Button
                        key={section.id}
                        style={{
                          backgroundColor: selectedButtonIndex === index + 1 ?'#F9EBEB':"rgba(230, 230, 232, 1)",
                          color: selectedButtonIndex === index + 1 ? '#C33531' : "black",
                          marginRight: '15px',
                          borderBottom: selectedButtonIndex === index + 1 ? '5px solid #C33531' : 'none',
                        }}
                        onClick={() => handleClick(index + 1)}
                      >
                        {section.section}
                      </Button>
                    ))}
                  </div>
                </Grid>
                <Grid item xl={1} lg={1} md={1}>
                  <Button
                    style={{ padding: '20px', backgroundColor: '#E6E6E8', width: '200px', height: '50px', marginLeft: isXs ? '0px': '20px', borderRadius: '12px' }}
                    onClick={submit}
                  >Finish test</Button>
                </Grid>
              </Grid>
            </Card>
            <Card style={{ width: isXs? '50%': '16%', display: 'flex', flexDirection: 'column', borderRadius: '24px', padding: '20px', marginTop: '20px', marginLeft: isXs ? '0px':'30px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant="mediumthin">Test Timer</Typography>
                  <div style={{ fontSize: '20px', marginTop: '15px' }}>
                    <span style={{ backgroundColor: '#E6E6E8', padding: '8px', borderRadius: '8px' }}>{formatTimeUnit(hours)}</span>
                    <span style={{ fontSize: '24px', marginLeft: '5px' }}>:</span>
                    <span style={{ backgroundColor: '#E6E6E8', padding: '8px', borderRadius: '8px', marginLeft: '10px' }}>{formatTimeUnit(minutes)}</span>
                    <span style={{ fontSize: '24px', marginLeft: '5px' }}>:</span>
                    <span style={{ backgroundColor: '#E6E6E8', padding: '8px', borderRadius: '8px', marginLeft: '10px' }}>{formatTimeUnit(seconds)}</span>
                  </div>
                </div>
                {!isSmallerScreen && (
                  <img src={clock} style={{ marginTop: '10px', height: '66px', width: '57px' }} />
                )}
              </div>
            </Card>
          </div>
          <Grid container spacing={2}>
            <Grid item xl={2.5} lg={4} md={4}>
              <Card variant="scrollContained" className="scrollbar-cus" style={{ display: 'flex', flexDirection: 'column', borderRadius: '24px', padding: isXs ?"0px": '15px', marginTop: '30px', height: '82vh', overflow: "scroll" }}>
                <div style={{ margin: isSmallerScreen ? '10px' : '30px', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginBottom: '50px' }}>
                    {sections.map((section: any, sectionIndex: number) => (
                      <div key={section.id}>
                        <Typography variant="medium">{section?.section}</Typography><br />
                        {section.questions.map((_: any, questionIndex: any) => {
                          const questionId = `S${sectionIndex + 1}Q${questionIndex + 1}`;
                          const isAnswered = answers[sectionIndex + 1] && answers[sectionIndex + 1][questionIndex];
                          const isRevisitLater = revisitLater.includes(questionId);
                          const isVisited = visitedQuestions.includes(questionId);
                          const isCurrentQuestion = currentSectionIndex === sectionIndex && currentQuestionIndex === questionIndex;
                          return (
                            <Button
                              key={questionId}
                              variant="outlined"
                              style={{
                                marginRight: '10px',
                                marginBottom: 15,
                                marginTop: 10,
                                border: `${isCurrentQuestion ? '3px solid #193389' : '3px solid white'}`,
                                color: isCurrentQuestion ? "black" : answers[sectionIndex + 1] && answers[sectionIndex + 1][questionIndex] ? 'white' : _?.status === "Answer saved" ? 'white' : isVisited && !isAnswered && !isRevisitLater ? "white" : _?.flagged === true ? "white" : "black",
                                backgroundColor: isCurrentQuestion ? "white" :
                                  _?.status === "Answer saved" && _?.flagged === true ? "#7495FF" :
                                    revisitLater.includes(questionId) ? "#E4AF29" :
                                      _?.status === "Answer saved" ? 'rgb(122, 179, 124)'
                                        :
                                        _?.flagged === true ? "#E4AF29"
                                          : isVisited && !isAnswered && !isRevisitLater ? "#585A64" :
                                            "#e8e8e8",
                              }}
                              onClick={() => {
                                markQuestionAsVisited(currentSectionIndex + 1, currentQuestionIndex + 1);
                                handleClick(sectionIndex + 1);
                                setCurrentQuestionIndex(questionIndex);
                                setSelectedOption(answers[sectionIndex + 1] ? answers[sectionIndex + 1][questionIndex] : '');
                              }}
                            >
                              {questionIndex + 1}
                            </Button>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <Divider />
                  <div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <div style={{ backgroundColor: 'white', borderColor: '#193389', borderWidth: '2px', borderStyle: 'solid', width: '25px', borderRadius: '4px', height: 25 }} />
                          <Typography variant="medium" style={{ marginLeft: '10px' }}>
                            Current Question
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <div style={{ backgroundColor: '#60A563', borderColor: '#60A563', borderWidth: '1px', borderStyle: 'solid', width: '25px', borderRadius: '4px', height: 25 }} />
                          <Typography variant="medium" style={{ marginLeft: '10px' }}>
                            Answered
                          </Typography>
                        </div>
                      </div>
                      <Typography variant="medium">{totalQuestions >= AnsweredflaggedCount?.answerSavedCount ? AnsweredflaggedCount?.answerSavedCount - answeredAndFlaggedCount : totalQuestions - answeredAndFlaggedCount}</Typography>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ backgroundColor: '#E4AF29', borderColor: '#E4AF29', borderWidth: '1px', borderStyle: 'solid', width: '25px', height: 25, borderRadius: '4px' }} />
                        <Typography variant="medium" style={{ marginLeft: '10px' }}>
                          Visit later
                        </Typography>
                      </div>
                      <Typography variant="medium">{AnsweredflaggedCount?.flaggedCount - answeredAndFlaggedCount}</Typography>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ backgroundColor: '#7495FF', borderColor: '#60A563', borderWidth: '1px', borderStyle: 'solid', width: 25, borderRadius: 4, height: 25 }}>
                          <CircleIcon style={{ color: "#7495FF", width: 12, height: 17, float: "right" }} />
                        </div>
                        <Typography variant="medium" style={{ marginLeft: '10px' }}>
                          Answered and marked for revisit
                        </Typography>
                      </div>
                      <Typography variant="medium">
                        {answeredAndFlaggedCount}
                      </Typography>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ backgroundColor: '#585A64', borderColor: '#585A64', borderWidth: '1px', borderStyle: 'solid', width: 25, borderRadius: 4, height: 25 }}>
                        </div>
                        <Typography variant="medium" style={{ marginLeft: '10px' }}>
                          Not Answered
                        </Typography>
                      </div>
                      <Typography variant="medium">
                        {unansweredAndunFlaggedCount}
                      </Typography>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ backgroundColor: '#e8e8e8', borderColor: '#e8e8e8', borderWidth: '1px', borderStyle: 'solid', width: '25px', height: 25, borderRadius: '4px' }} />
                        <Typography variant="medium" style={{ marginLeft: '10px' }}>
                          Not Visited
                        </Typography>
                      </div>
                      <Typography variant="medium">
                        {unansweredAndunFlaggedCount - notAnsweredCount >= 0 ? unansweredAndunFlaggedCount - notAnsweredCount : 0}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={9.5} lg={8} md={8}>
              <Card style={{ display: 'flex', flexDirection: 'column', borderRadius: '24px', padding: '15px', marginTop: '30px', height: '82vh', position: 'relative' }}>
                <Grid container spacing={2} style={{ margin: isXs?'1px':'20px' }}>
                  <Grid item xl={12} lg={12} md={12}>
                    <div>
                      <ol>
                        <Typography variant="mediumthin" style={{ display: 'flex' }}>
                          {sections && sections.length > 0 && (
                            <Grid container style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }} >
                              <Grid lg={8} style={{ display: "flex-start", alignItems: "center" }}>{currentQuestionIndex + 1}.<div style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: `${sections[currentSectionIndex].questions[currentQuestionIndex].question}` }} /> </Grid>
                              <Grid lg={4}>
                                <div onClick={() => { handleRevisitLater(sections[currentSectionIndex].questions[currentQuestionIndex]); }}>
                                  {sections[currentSectionIndex].questions[currentQuestionIndex].flagged ? (
                                    <div style={{ display: 'flex' }}> <BookmarkAddedIcon /> Revisit later </div>
                                  ) : (
                                    <div style={{ display: 'flex' }}><BookmarkBorderIcon />  Revisit later</div>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        </Typography>
                        <div className={'scrollbar-cus'} style={{ height: "35vh", overflow: "scroll" }}>
                          <RadioGroup
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
                            value={selectedOption}
                            onChange={(event: any) => handleChange(event.target.value)}
                          >
                            {sections && sections.length > 0 && sections[currentSectionIndex] && sections[currentSectionIndex].questions && (
                              sections[currentSectionIndex].questions[currentQuestionIndex].questionoption.map((item: any, index: any) => {
                                const question = sections[currentSectionIndex].questions[currentQuestionIndex];
                                const isAnswerSaved = question?.status === "Answer saved";
                                const isChecked = isAnswerSaved && item === question?.userresponse;
                                return (
                                  <FormControlLabel
                                    key={index}
                                    value={index.toString()}
                                    control={<Radio />}
                                    label={<span className='quizContent' dangerouslySetInnerHTML={{ __html: item }} />}
                                    checked={isChecked}
                                    onChange={() => setSelectedOption(index.toString())}
                                  />
                                );
                              })
                            )}
                          </RadioGroup>
                        </div>
                      </ol>
                    </div>
                  </Grid>
                </Grid>
                <Grid container style={{ position: 'absolute', bottom: '0', width: '100%'}}>
                  <Grid container style={{ padding: '18px 24px', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item>
                      <Button onClick={() => { clearResponse(sections[currentSectionIndex].questions[currentQuestionIndex]) }}>
                        Clear Response
                      </Button>
                    </Grid>
                    <Grid item>
                      {!isFirstQuestion && (
                        <Button
                          onClick={handlePreviousQuestion}
                          style={{
                            padding: '20px',
                            backgroundColor: '#E6E6E8',
                            width: isSmallerScreen ? '250px' : '250px',
                            height: '50px',
                            marginLeft: '20px',
                            borderRadius: '12px',
                            marginRight: '20px'
                          }}
                        >
                          Previous question
                        </Button>
                      )}
                      <Button
                        className="buttonmedium"
                        style={{
                          background: 'linear-gradient(90deg, #581212 -92.71%, #C33531 100%)',
                          width: isSmallerScreen ? '150px' : '204px',
                          color: 'white',
                          marginRight: '25px',
                        }}
                        variant="contained"
                        onClick={() => { isLastQuestion ? submit() : handleNextQuestion() }}
                      >
                        {isLastQuestion ? "Submit" :
                          <span>{currentQuestionIndex === sections[currentSectionIndex]?.questions.length - 1 ? "Next section" : "Next Question"}</span>}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </>
        :
        <TestSummary cartdatas={cartdatas} goBackToTest={goBackToTest} summaryData={SummaryData} remainingMinutes ={remainingMinutes} remainingSeconds={remainingSeconds} remainingHours={remainingHours} />
      }
    </div>
  );
};

export default TestAssesments;