import { Box, Button, Typography, Snackbar, SnackbarContent, Dialog, DialogActions, DialogTitle, IconButton, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import KenColors from "../../../Helpers/Themes/kenColors";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useAppFormContext } from "../../../Helpers/Contexts/formContext";
import { useForm } from "react-hook-form";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { getUserDetails, onboarding } from "../../../Utils/apiService";
import EditIcon from '@mui/icons-material/Edit';

const ProfileImage: React.FunctionComponent<any> = (props: any) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [imageBase64, setImageBase64] = useState('');

    const { state, dispatch } = useAppFormContext();
    const { handleSubmit, getValues, setValue } = useForm({ mode: 'onChange' });
    const UserData = localStorage.getItem('userData');
    const userDatas = UserData ? JSON.parse(UserData) : null;

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const BootstrapDialog = styled(Dialog)(({ theme }: any) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const toBase64 = (file: File | null) =>
        new Promise((resolve, reject) => {
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
            }
        });

    useEffect(() => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            if (parsedData.profile && parsedData.profile[0].base64) {
                setImageBase64(parsedData.profile[0].base64);
            }
        }
    }, []);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const file = (target.files || [])[0] as File;

        if (!file) {
            dispatch({ type: "updateFiles", value: { fieldName: props?.name, fileData: '' } });
            return;
        }

        const validFormats = ['image/png', 'image/jpg', 'image/jpeg'];
        if (!validFormats.includes(file.type)) {
            setSnackbarOpen(true);
            setSnackbarMessage('We are only accepting PNG, JPG, and JPEG formats.');
            return;
        }
        if (file.size > 2 * 1024 * 1024) { // 2MB in bytes
            setSnackbarOpen(true);
            setSnackbarMessage('Please upload an image below 2MB.');
            return;
        }
        try {
            const base64Image: any = await toBase64(file);
            setImageBase64(base64Image); // Update state to reflect the new image
            // Dispatch the update to form context
            dispatch({ type: 'updateFiles', value: { fieldName: props?.name, fileData: base64Image.split(',')[1] } });
            setValue(props.name, [{ base64: base64Image }]);
        } catch (error) {
            console.error('Error converting image to Base64:', error);
        }
    };

    const submit = () => {
        const userUpdate: any = {
            method: 'post',
            users: {
                id: userDatas?.user_id,
                filename: "test1",
                profile_picture: state["files"][props?.name],
                customfields: [
                ],

            },
        };
        onboarding(userUpdate).then((res: any) => {
            if(res?.status===200){
                // const userData = {
                //     name: res?.data?.fullname,
                //     email: res?.data?.email,
                //     profile_picture: res?.data?.profile_picture,
                //     user_id: res?.data?.id,
                //     token: res?.data?.token
                // }
                // localStorage.setItem("userData", JSON.stringify(userData));
                window.location.reload();
            }
        })
        handleClose();
    }
    
    return (
        <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <div onClick={handleClickOpen} style={{ position: 'relative', display: 'inline-block' }}>
                {userDatas.profile_picture ? (
                    <img style={{ width: "100px", height: "100px", borderRadius: "50%" }} src={userDatas.profile_picture} alt="profile" />
                ) : (
                    <AccountCircleIcon fontSize="large" style={{ width: "100px", height: "100px" }} />
                )}
                <div style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 0,
                    backgroundColor: '#C33531',
                    borderRadius: '50%',
                    padding: 3,
                    width: 23,
                    height: 23
                }}>
                    <center><EditIcon style={{ color: 'white', height: 20, width: 20 }} /></center>
                </div>
            </div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                PaperProps={{
                    style: {
                        width: '400px',
                        maxWidth: 'none',
                    }
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Update Profile Image
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ textAlign: 'center' }}>
                    <Box
                        sx={{
                            background: imageBase64
                                ? `url(${imageBase64})`
                                : `url(${userDatas?.profile_picture})`,
                            height: "130px",
                            width: "130px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            margin: "0 auto 20px",
                        }}
                    >
                        <Typography variant="medium" component={"caption"} color={KenColors.ClientBlack2}>
                            {state["files"][props?.name] ? "" : ""}
                        </Typography>
                    </Box>
                    <input
                        type="file"
                        hidden
                        multiple={false}
                        id={props?.name}
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                    <Button
                        className="buttonText-medium"
                        variant="contained"
                        startIcon={<FileUploadOutlinedIcon fontSize="medium" style={{ color: "white" }} />}
                        component="label"
                        style={{ padding: 5, marginTop: 10 }}
                    >
                        Upload profile photo<span style={{ color: "red", padding: 0 }}>*</span>
                        <input
                            type="file"
                            hidden
                            id={props?.name}
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </Button>
                    <Box mt={2}>
                        <Typography variant="smallthin" component={"h6"} color={KenColors.ClientBlack}>
                            Allowed file types: png, jpg, jpeg.
                        </Typography>
                        <Typography variant="smallthin" component={"h6"} color={KenColors.ClientBlack}>
                            Image dimension: 120*120
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submit}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            >
                <SnackbarContent sx={{ backgroundColor: "red" }} message={snackbarMessage} />
            </Snackbar>
        </Box>
    );
};

export default ProfileImage;
