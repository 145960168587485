import { styled } from "@mui/system";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { ReactChildren } from "../../interfaces/Common";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    fontSize: "20px",
    fontWeight: "500",
  },
  "&.notistack-MuiContent-error": {
    fontSize: "20px",
    fontWeight: "500",
  },
  "&.notistack-MuiContent-default": {
    fontSize: "20px",
    fontWeight: "500",
  },
  "&.notistack-MuiContent-warning": {
    fontSize: "20px",
    fontWeight: "500",
  },
  "&.notistack-MuiContent-info": {
    fontSize: "20px",
    fontWeight: "500",
  },
}));

const AppSnackBar: React.FunctionComponent<ReactChildren> = ({ children }) => {
  return (
    <SnackbarProvider Components={{ success: StyledMaterialDesignContent, error: StyledMaterialDesignContent, info: StyledMaterialDesignContent, default: StyledMaterialDesignContent, warning: StyledMaterialDesignContent }} anchorOrigin={{ horizontal: "center", vertical: "top" }}>
      {children}
    </SnackbarProvider>
  );
};

export default AppSnackBar;
