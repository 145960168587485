import { Breadcrumbs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import KenColors from '../../Helpers/Themes/kenColors';

const useStyles = makeStyles(() => ({
  listText: {
    fontSize: '20px !important',
    fontWeight: '600 !important',
    textDecoration: 'none',
    textTransform: 'capitalize',
    margin: '0px !important',
    padding: '0px !important',
  },
  listText1: {
    fontSize: '20px !important',
    fontWeight: '400 !important',
    textDecoration: 'none',
    marginRight: '10px !important',
    margin: '0px !important',
    padding: '0px !important',
  },
  listText2: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
    textDecoration: 'none',

    textTransform: 'capitalize',
    margin: '0px !important',
    padding: '0px !important',
  },
}));

const AppBreadCrumbs: React.FunctionComponent = () => {
  const { pathname, state } = useLocation();
  const UserData = localStorage.getItem("userData");
  const userData = UserData ? JSON.parse(UserData) : null;
  const classes = useStyles();
  const navigateHistory: Array<string> = pathname.split('/').filter((items) => {
    return items && items !== '';
  });
  const navigateState: null | string = state?.breadCrumbs;
  let customPath: string = '';

  // Check if there is only one segment in the path
  if (navigateHistory.length === 1) {
    return (
      <div style={{ display: 'flex', padding: '13px 0px' }}>
        <Typography className={classes.listText1}>👋🏻 Welcome back,</Typography>
        <Typography className={classes.listText} color={KenColors.ClientBlack2}>
          {userData?.name}
        </Typography>
      </div>
    );
  }
  const previousStep = navigateHistory[navigateHistory.length - 2];

  return (
    <div>
      <Typography className={classes.listText} color={KenColors.ClientBlack2}>
        {previousStep}
      </Typography>
      <Breadcrumbs separator=">" style={{ color: KenColors.ClientBlack2, marginTop: '3px' }}>
        {navigateHistory.map((items, index) => {
          customPath = customPath + `;${items}`;
          const isLastIndex = index === navigateHistory.length - 1;
          const newPath = customPath.replace(/;/g, '/');
          return index === navigateHistory.length - 1 && navigateState ? (
            <Typography key={index} className={classes.listText2} color={KenColors.ClientBlack2}>
              {navigateState}
            </Typography>
          ) : (
            <Link key={index} style={{ color: isLastIndex ? KenColors.ClientBlack2 : KenColors.ClientBlue2 }} className={classes.listText2} to={`${newPath}`}>
              {items}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default AppBreadCrumbs;
